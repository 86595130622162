import React, {useEffect} from "react";
import { MDBBtn } from "mdbreact";
import { db } from "../Firebase";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FooterBox from "../FooterBox";

export default function Ersthinweis({data}) {

  const [showFlash, setShowFlash] = React.useState(false);
  const [flashMessage, setFlashMessage] = React.useState("");
  const [flashType, setFlashType] = React.useState("");

  const [title, setTitle] = React.useState("");
  const [text, setText] = React.useState("");
  const [link, setLink] = React.useState("");

  const [titleEN, setTitleEN] = React.useState("");
  const [textEN, setTextEN] = React.useState("");
  const [linkEN, setLinkEN] = React.useState("");

  const getMappedData = (key, lang) => {
    if(!!data && !!data[lang] && !!data[lang][key]){
      return data[lang][key];
    }
  }

  useEffect(() => {
    setTitle(getMappedData("title", "de"));
    setText(getMappedData("text", "de"));
    setLink(getMappedData("link", "de"));

    setTitleEN(getMappedData("title", "en"));
    setTextEN(getMappedData("text", "en"));
    setLinkEN(getMappedData("link", "en"));
  }, [data])


  const save = () => {

    confirmAlert({
      title: "Änderungen speichern",
      message: "Sind Sie sicher, dass Sie diese Änderungen speichern wollen?",
      buttons: [
        {
          label: "Änderungen speichern",
          onClick: () => {
            db.collection("Admin")
              .doc("admin")
              .update({
                Ersthinweis: {
                  de: {
                    title: title,
                    text: text,
                    link: link,
                  },
                  en: {
                    title: titleEN,
                    text: textEN,
                    link: linkEN,
                  }

                },
              })
              .then(() => {
                setFlashType("success");
                setFlashMessage("Änderungen wurden erfolgreich übernommen");
                setShowFlash(true);
              });
          },
        },
        {
          label: "Abbrechen",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div>
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="title" style={{ marginBottom: 5, fontSize: 17 }}>
            Titel - DE
          </label>
          <input
              type="text"
              id={"title"}
              className="form-control form-control-md"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", marginTop: 5 }}>
          <label htmlFor="text" style={{ marginBottom: 5, fontSize: 17 }}>
            Text - DE
          </label>
          <textarea
              id="text"
              rows={5}
              className="form-control form-control-md textNachricht"
              style={{width: "100%"}}
              onChange={(e) => {
                setText(e.target.value)
              }}
              value={text}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", marginTop: 5 }}>
          <label htmlFor="link" style={{ marginBottom: 5, fontSize: 17 }}>
            Link - DE
          </label>
          <input
              type="text"
              id={"link"}
              className="form-control form-control-md"
              value={link}
              onChange={(e) => {
                setLink(e.target.value)
              }}
          />
        </div>
      </div>
      <div style={{marginTop: 50, paddingTop: 10, borderTop: "1px solid rgba(0,0,0,0.2)"}}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="titleEN" style={{ marginBottom: 5, fontSize: 17 }}>
            Titel - EN
          </label>
          <input
              type="text"
              id={"titleEN"}
              className="form-control form-control-md"
              value={titleEN}
              onChange={(e) => {
                setTitleEN(e.target.value)
              }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", marginTop: 5 }}>
          <label htmlFor="textEN" style={{ marginBottom: 5, fontSize: 17 }}>
            Text - EN
          </label>
          <textarea
              id="textEN"
              rows={5}
              className="form-control form-control-md textNachricht"
              style={{width: "100%"}}
              onChange={(e) => {
                setTextEN(e.target.value)
              }}
              value={textEN}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", marginTop: 5 }}>
          <label htmlFor="linkEN" style={{ marginBottom: 5, fontSize: 17 }}>
            Link - EN
          </label>
          <input
              type="text"
              id={"linkEN"}
              className="form-control form-control-md"
              value={linkEN}
              onChange={(e) => {
                setLinkEN(e.target.value)
              }}
          />
        </div>
      </div>
      <MDBBtn color="dark" onClick={save} style={{marginTop: 20}}>
        Änderungen speichern
      </MDBBtn>
      {showFlash && (
        <FooterBox
          type={flashType}
          message={flashMessage}
          onExit={() => setShowFlash(false)}
        />
      )}
    </div>
  );
}
