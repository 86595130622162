import React, { useEffect } from "react"
import { MdCloudDownload } from "react-icons/md"
import { MDBBtn } from "mdbreact"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import format from "date-fns/format"

export default function StatistikBox(props) {
  const options = {
    time: {
      useUTC: false,
    },
    chart: {
      type: "line",
      zoomType: "xy",
      ignoreHiddenSeries: false,
      animation: Highcharts.svg, // don't animate in old IE
      marginRight: 10,
      // events: {
      //   click: function () {
      //     console.log(this.series.chart);
      //     // this.series.chart.update({
      //     //   tooltip: {
      //     //     enabled: tooltipEnabled,
      //     //   },
      //     // });
      //     // tooltipEnabled = tooltipEnabled ? false : true;
      //   },
      // },
    },

    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      tickPixelInterval: 150,
      dateTimeLabelFormats: {
        millisecond: "%H:%M",
        second: "%H:%M",
        minute: "%H:%M",
        hour: "%H:%M",
        day: "%e. %b",
        week: "%e. %b",
        month: "%b '%y",
        year: "%Y",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      max: 10,
      plotLines: [
        {
          value: 0,
          width: 1,
          color: "#808080",
        },
      ],
    },

    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          this.series.name +
          "</b><br/>" +
          Highcharts.dateFormat("%d.%m.%Y ", this.x) +
          "<br/>" +
          Highcharts.numberFormat(this.y, 2)
        )
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: "circle",

          radius: 3.5,
        },
      },
      linearregression: {
        // shared options for all linearregression series
      },
    },
    series: [
      {
        data: props.data ? props.data : [],
        name: props.name,
        type: "line",
        visible: props.show === false ? false : true,
      },
      props.data2
        ? {
            data: props.data2,
            name: props.name,
            type: "line",
            dashStyle: "dash",
          }
        : [],
    ],
  }

  const download = () => {
    let csvContent

    const toDateArray = props.data.map((eintrag) => {
      return [format(new Date(eintrag[0]), "d.M.yyyy hh:mm"), eintrag[1]]
    })

    csvContent = "Datum;Wert\n" + toDateArray.map((e) => e.join(";")).join("\n")

    var aTag = document.getElementById(
      "dwnButton" + props.name.replace(" ", "")
    )

    aTag.href = "data:attachment/csv," + encodeURIComponent(csvContent)
    aTag.target = "_blank"
    const test = new Date()

    aTag.download =
      format(test, "d.M.yyyy ") +
      test.getHours() +
      ":" +
      test.getMinutes() +
      "_" +
      props.user +
      "_" +
      props.name.replace(" ", "_") +
      ".csv"

    var encodedUri = encodeURI(csvContent)
    window.open(encodedUri)
  }

  return (
    <div className="statBox">
      <div className="headline">
        <h4>
          Statistik {props.index}
          {props.zusatz ? " - " + props.zusatz : ""}
        </h4>
        {!!!props.exportDisabled &&
            <a id={"dwnButton" + props.name.replace(" ", "")}>
              <MDBBtn className="downBtn" onClick={() => download()}>
                <MdCloudDownload size={25} />
              </MDBBtn>
            </a>
        }

      </div>

      <div className="statMainBox">
        <HighchartsReact
          className="statBox"
          highcharts={Highcharts}
          options={options}
        />
      </div>
    </div>
  )
}
