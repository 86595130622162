import React, { useEffect } from "react"
import "./index.css"
import { MDBBtn } from "mdbreact"
import Frage from "./Frage"
import { db } from "../Firebase"
import AddFrageModal from "./AddFrageModal"
import Verlaufseingabe from "./Verlaufseingabe"
import Ersthinweis from "./Ersthinweis";
import {makeId} from "../../utils/globals";
import FooterBox from "../FooterBox";
import AdminUsers from "./AdminUsers";

let data = ""

export default function EinstellungsScreen() {
  const [fragen, setFragen] = React.useState([])
  const [side, setSide] = React.useState("frage")
  const [verlauf, setVerlauf] = React.useState({})
  const [ersthinweis, setErsthinweis] = React.useState({})
  const [errors, setErrors] = React.useState([])

  const [showFlash, setShowFlash] = React.useState(false)
  const [flashMessage, setFlashMessage] = React.useState("")
  const [flashType, setFlashType] = React.useState("")

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    let data
    db.collection("Admin")
      .doc("admin")
      .get()
      .then((doc) => {
        if (doc.exists) {
          const dbData = doc.data()
          setVerlauf(dbData["Verlauf"])
          setErsthinweis(dbData["Ersthinweis"])
          data = dbData["FragenNEU"]
          let _fragen = data.map(entry => {
            return {
              ...entry,
              frageDE: !!!entry.frageDE ? entry.frage : entry.frageDE,
              frageEN: !!!entry.frageEN ? entry.frage : entry.frageEN,
              checkboxes: !!entry.checkboxes ? entry.checkboxes : [],
              modus: !!!entry.modus ? entry.modalität : entry.modus,
              verpflichtend: !!!entry.verpflichtend ? false : !!entry.verpflichtend,
              id: makeId()
            }
          })
          setFragen(_fragen)
        } else {
          return undefined
        }
      })
  }

  useEffect(() => {
    if (side === "frage") {
      document.getElementById("fragenSettings").classList.add("activeBtn")
      document.getElementById("verlaufSettings").classList.remove("activeBtn")
      document.getElementById("ersthinweisSettings").classList.remove("activeBtn")
      document.getElementById("usersSettings").classList.remove("activeBtn")
    } else if(side === "verlauf"){
      document.getElementById("fragenSettings").classList.remove("activeBtn")
      document.getElementById("verlaufSettings").classList.add("activeBtn")
      document.getElementById("ersthinweisSettings").classList.remove("activeBtn")
      document.getElementById("usersSettings").classList.remove("activeBtn")
    } else if(side === "ersthinweis"){
      document.getElementById("fragenSettings").classList.remove("activeBtn")
      document.getElementById("verlaufSettings").classList.remove("activeBtn")
      document.getElementById("ersthinweisSettings").classList.add("activeBtn")
      document.getElementById("usersSettings").classList.remove("activeBtn")
    } else if(side === "users"){
      document.getElementById("fragenSettings").classList.remove("activeBtn")
      document.getElementById("verlaufSettings").classList.remove("activeBtn")
      document.getElementById("ersthinweisSettings").classList.remove("activeBtn")
      document.getElementById("usersSettings").classList.add("activeBtn")
    }
  }, [side])

  const saveFragen = () => {
    let _errors = validateFragen();
    if(_errors.length === 0){

      let _toSave = fragen.map(frage => {
        delete frage['modalität'];
        delete frage['frage'];
        return frage
      })

      db.collection("Admin").doc("admin").update({
        FragenNEU: _toSave,
      }).then(_ => {
        setFlashMessage("Änderungen erfolgreich gespeichert!")
        setFlashType("success")
        setShowFlash(true)
      })
    }
  }

  const addFrage = () => {
    setFragen([...fragen, {modalität: "Texteingabe", id: makeId()}]);
  }

  const removeFrage = (id) => {
    let entries = [...fragen];
    entries = entries.filter(entry => entry.id !== id)
    setFragen([...entries]);
  }

  const validateFragen = () => {
    let _errors = [];
    setErrors([]);

    if(!!!fragen || fragen.length === 0){

    } else {
      let i = 0;
      fragen.forEach(frage => {
        if (!!!frage.frageDE) {
          _errors.push({
            index: i,
            key: "frageDE",
            error: "Pflichtfeld"
          })
        }
        if (!!!frage.frageEN) {
          _errors.push({
            index: i,
            key: "frageEN",
            error: "Pflichtfeld"
          })
        }

        if (!!!frage.db) {
          _errors.push({
            index: i,
            key: "db",
            error: "Pflichtfeld"
          })
        }

        i++;
      })
    }



    setErrors(_errors)
    return _errors;
  }

  const onChange = (idx, key, value) => {
    const values = fragen.map((entry, sidx) => {
      if (idx !== sidx) return entry;
      return {...entry, [key]: value};
    });
    setFragen(values);
  }

  const addToCheckBoxArray = (fragenIndex) => {
    const values = fragen.map((entry, sidx) => {
      if (fragenIndex !== sidx) return entry;
      if(!!!entry.checkboxes){
        entry.checkboxes = [];
      }
      entry.checkboxes = [...entry.checkboxes, {titelDE: "", titelEN: "", id: makeId()}]
      return {...entry};
    });
    setFragen(values);
  }

  const removeFromCheckBoxArray = (fragenIndex, id) => {
    const values = fragen.map((entry, sidx) => {
      if (fragenIndex !== sidx) return entry;
      entry.checkboxes = entry.checkboxes.filter(c => c.id !== id);
      return {...entry};
    });
    setFragen(values);
  }

  const getContent = () => {
    if(side === "frage"){
      if(fragen.length > 0){
        return <div>
          {fragen.map((frage, index) => (
              <Frage
                  index={index}
                  frage={frage}
                  removeFrage={removeFrage}
                  onChange={onChange}
                  addToCheckBoxArray={addToCheckBoxArray}
                  removeFromCheckBoxArray={removeFromCheckBoxArray}
                  errors={errors}
              />
          ))}

          <MDBBtn color="outline-dark" block onClick={addFrage} style={{marginTop: 10, marginBottom: 10}}>
            Frage hinzufügen
          </MDBBtn>

          <MDBBtn color="dark" block onClick={saveFragen} style={{marginTop: 20, marginBottom: 30}}>
            Änderungen speichern
          </MDBBtn>
        </div>
      }
    } else if(side === "verlauf"){
        return <Verlaufseingabe data={verlauf} />
    } else if(side === "ersthinweis"){
        return <Ersthinweis data={ersthinweis}/>
    } else if(side === "users"){
      return <AdminUsers />
    }
  }

  return (
    <div>
      <main>
        <div className="mainContainer">
          <div className="leftContainer">
            <MDBBtn
              className="basicBtns"
              id="fragenSettings"
              onClick={() => setSide("frage")}
            >
              Fragebogen
            </MDBBtn>
            <MDBBtn
              className="basicBtns"
              id="verlaufSettings"
              onClick={() => setSide("verlauf")}
            >
              Verlaufseingabe
            </MDBBtn>
            <MDBBtn
                className="basicBtns"
                id="ersthinweisSettings"
                onClick={() => setSide("ersthinweis")}
            >
              Ersthinweis
            </MDBBtn>
            <MDBBtn
                className="basicBtns"
                id="usersSettings"
                onClick={() => setSide("users")}
            >
              Admin-Benutzer
            </MDBBtn>
          </div>
          <div className="rightContainer">
            {getContent()}
          </div>
        </div>
      </main>
      {showFlash && (
          <FooterBox
              type={flashType}
              message={flashMessage}
              onExit={() => setShowFlash(false)}
          />
      )}
    </div>
  )
}
