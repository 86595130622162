import React from "react";
import { MDBBtn } from "mdbreact";
import { db } from "../Firebase";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FooterBox from "../FooterBox";

export default function Verlaufseingabe(props) {
  const [wieOft, setWieOft] = React.useState(props.data.wieOft);
  const [wieLang, setWieLang] = React.useState(props.data.wieLang);
  const [showFlash, setShowFlash] = React.useState(false);
  const [flashMessage, setFlashMessage] = React.useState("");
  const [flashType, setFlashType] = React.useState("");

  const save = () => {
    confirmAlert({
      title: "Änderungen speichern",
      message: "Sind Sie sicher, dass Sie diese Änderungen speichern wollen?",
      buttons: [
        {
          label: "Änderungen speichern",
          onClick: () => {
            db.collection("Admin")
              .doc("admin")
              .update({
                Verlauf: {
                  wieOft: wieOft,
                  wieLang: wieLang,
                },
              })
              .then(() => {
                setFlashType("success");
                setFlashMessage("Änderungen wurden erfolgreich übernommen");
                setShowFlash(true);
              });
          },
        },
        {
          label: "Abbrechen",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div>
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="wieOft" style={{ marginBottom: 5, fontSize: 17 }}>
            Wie oft pro Tag können Benutzer neue Eingaben erstellen
          </label>
          <input
            type="number"
            id="wieOft"
            value={wieOft}
            onChange={(event) => setWieOft(event.target.value)}
            style={{ width: "50%" }}
            className="specialInput"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="wieLang" style={{ marginBottom: 5, fontSize: 18 }}>
            Wie lang nach der ersten Eingabe muss vergehen, damit der Benutzer
            eine neue Eingabe tätigen kann.
          </label>
          <input
            type="number"
            id="wieLang"
            value={wieLang}
            onChange={(event) => setWieLang(event.target.value)}
            style={{ width: "50%" }}
            className="specialInput"
          />
        </div>
      </div>
      <MDBBtn color="dark" onClick={save}>
        Änderungen speichern
      </MDBBtn>
      {showFlash && (
        <FooterBox
          type={flashType}
          message={flashMessage}
          onExit={() => setShowFlash(false)}
        />
      )}
    </div>
  );
}
