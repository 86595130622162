import React, { useEffect } from "react"
import "./index.css"
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn,
} from "mdbreact"
import Checkbox from "@material-ui/core/Checkbox"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import FooterBox from "../FooterBox"
import { db } from "../Firebase"

export default function BenarchtigScreen() {
  const [push, setPush] = React.useState(false)
  const [email, setEmail] = React.useState(false)

  const [titel, setTitel] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [showFlash, setShowFlash] = React.useState(false)
  const [flashMessage, setFlashMessage] = React.useState("")
  const [flashType, setFlashType] = React.useState("")

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    db.collection("Admin")
      .doc("admin")
      .onSnapshot((doc) => {
        let data = doc.data()["benachrichtigungen"]

        setPush(data.push)
        setEmail(data.email)
        setTitel(data.titel)
        setMessage(data.message)
      })
  }

  const handleTitel = (e) => {
    setTitel(e.target.value)
  }
  const handleMessage = (e) => {
    setMessage(e.target.value)
  }

  const save = () => {
    confirmAlert({
      title: "Änderungen speichern",
      message: "Sind Sie sicher, dass Sie diese Änderungen speichern wollen?",
      buttons: [
        {
          label: "Änderungen speichern",
          onClick: () => {
            db.collection("Admin")
              .doc("admin")
              .update({
                benachrichtigungen: {
                  message: message,
                  titel: titel,
                  push: push,
                  email: email,
                },
              })
              .then(() => {
                setFlashMessage("Änderungen erfolgreich gespeichert!")
                setFlashType("success")
                setShowFlash(true)
              })
          },
        },
        {
          label: "Abbrechen",
          onClick: () => {},
        },
      ],
    })
  }

  return (
    <div>
      <main>
        <h5>Benachrichtigung wählen:</h5>

        <div>
          <label htmlFor="push">Push-Nachrichten</label>
          <Checkbox
            checked={push}
            onChange={() => setPush(!push)}
            color="default"
            inputProps={{ "aria-label": "checkbox with default color" }}
            id="push"
          />
          <label htmlFor="email-nachricht">Email</label>
          <Checkbox
            checked={email}
            onChange={() => setEmail(!email)}
            color="default"
            inputProps={{ "aria-label": "checkbox with default color" }}
            id="email-nachricht"
          />
        </div>

        <label htmlFor="title" style={{ fontSize: "16px", marginTop: "15px" }}>
          Titel
        </label>
        <input
          type="text"
          id="title"
          className="form-control form-control-md"
          style={{ width: "70%" }}
          onChange={handleTitel}
          value={titel}
        />
        <label
          htmlFor="message"
          style={{ fontSize: "16px", marginTop: "15px" }}
        >
          Nachricht
        </label>
        <textarea
          id="message"
          rows={5}
          className="form-control form-control-md textNachricht"
          onChange={handleMessage}
          value={message}
        />
        <MDBBtn color="dark" style={{ marginTop: "20px" }} onClick={save}>
          Änderungen speichern
        </MDBBtn>
      </main>
      {showFlash && (
        <FooterBox
          type={flashType}
          message={flashMessage}
          onExit={() => setShowFlash(false)}
        />
      )}
    </div>
  )
}
