import React from "react";
import { MDBBtn } from "mdbreact";
import "./index.css";
import { Dropdown } from "semantic-ui-react";
import { db } from "../Firebase";

export default function LoginCodesScreen() {
  const [codesLoading, setCodesLoading] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const possibleCodes = [];
  let hundred = 101;
  for (var z = 0; z < hundred; z++) {
    if (z == 0) {
      continue;
    } else {
      possibleCodes.push({
        key: z.toString(),
        text: z.toString(),
        value: z.toString(),
      });
    }
  }

  const [currentAnzahl, setCurrentAnzahl] = React.useState();
  const [codes, setCodes] = React.useState([]);

  const generate = async () => {
    setCodesLoading(true);

    if (currentAnzahl) {
      setCopied(false);
      let newCodes = [];
      let check = false;
      for (var i = 0; i < currentAnzahl; i++) {
        if (check) {
          break;
        }
        var length = 8,
          charset =
            "abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789",
          code = "";
        for (var a = 0, n = charset.length; a < length; ++a) {
          code += charset.charAt(Math.floor(Math.random() * n));
        }

        newCodes.push(code);
        while (codes.includes(codes)) {
          var length = 8,
            charset =
              "abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789",
            code = "";
          for (var b = 0, n = charset.length; b < length; ++b) {
            code += charset.charAt(Math.floor(Math.random() * n));
          }
        }

        await db
          .collection("Logincodes")
          .doc(`${code}`)
          .set({ code: code, eintrag: new Date() })
          .then(() => {
            if (i == currentAnzahl) {
              check = true;
            }
          })
          .catch((error) => {
            setCodesLoading(false);
            alert(error.message());
          });
      }
      setCodesLoading(false);

      setCodes(newCodes);
    } else {
      setCodesLoading(false);
      alert(
        "Bitte wählen Sie eine Anzahl an Codes die Sie generieren möchten!"
      );
    }
  };

  const copyAllCodes = () => {
    let copyString = "";
    codes.forEach((item) => {
      copyString += item + "\n";
    });
    navigator.clipboard.writeText(copyString).then(() => {
      setCopied(true);
    });
  };

  return (
    <div>
      <main>
        <div className="topContainer">
          <p>Login Codes generieren</p>
        </div>
        <div className="chooseContainer">
          <p style={{ marginBottom: 0 }}>
            Wählen Sie die Anzahl der benötigten Login-Codes aus:
          </p>
          <Dropdown
            placeholder="Anzahl"
            search
            selection
            style={{
              width: 100,
              minWidth: 100,
              maxWidth: 100,
              borderColor: "black",
              borderWidth: 1,
            }}
            options={possibleCodes}
            onChange={(event, data) => setCurrentAnzahl(parseInt(data.value))}
          />
          <MDBBtn
            color="dark"
            className="benutzerAnlegenBtn"
            onClick={() => generate()}
            style={{
              height: 50,
              marginLeft: 20,
            }}
          >
            Login Codes generieren
          </MDBBtn>
        </div>
        <div className="allCodes">
          {codesLoading ? (
            <p>Lädt</p>
          ) : codes && codes.length > 0 ? (
            codes.map((item) => <p>{item}</p>)
          ) : (
            <p>Keine Codes</p>
          )}
        </div>
        {codesLoading
          ? ""
          : codes &&
            codes.length > 0 && (
              <div className="copyCodes">
                <MDBBtn
                  color="light"
                  style={{ borderColor: "black", borderWidth: 1 }}
                  onClick={copyAllCodes}
                >
                  {copied ? "Kopiert!" : "Codes kopieren"}
                </MDBBtn>
              </div>
            )}
      </main>
    </div>
  );
}
