import React, {useEffect, useState} from "react";
import Select from "react-select";
import {db} from "../Firebase";

export default function RetailerSelect({id, onChange, value}){

    const [options, setOptions] = useState([]);

    const colourStyles = {
        menuList: styles => ({
            ...styles,
        }),
        option: (styles, {isFocused, isSelected}) => ({
            ...styles,
        }),
        menu: base => ({
            ...base,
        }),
        control: (base, state) => ({
            ...base,
            backgroundColor: "rgba(222, 222, 222, 0.6) !important",
            borderRadius: "0.25rem",
            borderColor: "#ced4da",
            boxShadow: state.isFocused ? null : null,
        }),
    }

    useEffect(() => {
        db.collection("Benutzer").where("data.rolle", "==", "Retailer").get().then(res => {
            let toSet = [];
            res.forEach(function (doc, index) {
                const data = doc.data()["data"];
                toSet.push({
                    value: doc.id,
                    label: `${data.email} - ${data.kette} - ${data.filiale}`
                })
            });
            setOptions(toSet);
        });
    }, []);

    return <div>
        <Select
            id={id}
            value = {(options?options:[]).find(option => option.value === value)}
            className="basic-single"
            classNamePrefix="select"
            defaultValue={options.length > 0 ? options[0] : undefined}
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="retailer"
            options={options}
            placeholder={"Retailer auswählen"}
            styles={colourStyles}
            onChange={(value) => {
                if(!!onChange){
                    onChange(value.value)
                }
            }}
        />

    </div>

}