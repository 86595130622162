import React, {useEffect} from "react";
import {
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem, MDBInput, MDBBtn,
} from "mdbreact";
import {db} from "../Firebase";
import Checkbox from "@material-ui/core/Checkbox";

export default function Frage(props) {
    const [mode, setMode] = React.useState(props.frage.modus);

    const handleCheckboxTextChanged = (idx, key) => (evt) => {
        const array = props.frage.checkboxes.map((entry, sidx) => {
            if (idx !== sidx) return entry;
            return {...entry, [key]: evt.target.value};
        });
        props.onChange(props.index, "checkboxes", array)
    }

    const getInputFieldsBasedOnType = () => {
        if (!!mode) {
            if (mode === "Texteingabe") {
                return <div></div>
            } else if (mode === "Schieberegler") {
                return <div>
                    <h5>Daten für Schieberegler</h5>
                    <div className="row">
                        <div className="col">
                            <label htmlFor={"von" + props.index}>Skala von</label>
                            <input
                                type="number"
                                id={"von" + props.index}
                                className="form-control form-control-md"
                                value={props.frage.wertVon}
                                onChange={(evt) => props.onChange(props.index, "wertVon", evt.target.value)}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor={"bis" + props.index}>Skala bis</label>
                            <input
                                type="number"
                                id={"bis" + props.index}
                                className="form-control form-control-md"
                                value={props.frage.wertBis}
                                onChange={(evt) => props.onChange(props.index, "wertBis", evt.target.value)}                            />
                        </div>
                        <div className="col">
                            <label htmlFor={"step" + props.index}>Steps</label>
                            <input
                                type="number"
                                id={"step" + props.index}
                                className="form-control form-control-md"
                                value={props.frage.wertSchritte}
                                onChange={(evt) => props.onChange(props.index, "wertSchritte", evt.target.value)}                                 />
                        </div>
                    </div>

                    <div className="row" style={{marginBottom: 0}}>
                        <div className="col">
                            <label htmlFor={"step" + props.index}>Skala von (Text DE)</label>
                            <input
                                type="text"
                                id={"step" + props.index}
                                className="form-control form-control-md"
                                value={props.frage.wertVonTextDE}
                                onChange={(evt) => props.onChange(props.index, "wertVonTextDE", evt.target.value)}                             />
                        </div>
                        <div className="col">
                            <label htmlFor={"step" + props.index}>Skala bis (Text DE)</label>
                            <input
                                type="text"
                                id={"step" + props.index}
                                className="form-control form-control-md"
                                value={props.frage.wertBisTextDE}
                                onChange={(evt) => props.onChange(props.index, "wertBisTextDE", evt.target.value)}                             />
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 5}}>
                        <div className="col">
                            <label htmlFor={"step" + props.index}>Skala von (Text EN)</label>
                            <input
                                type="text"
                                id={"step" + props.index}
                                className="form-control form-control-md"
                                value={props.frage.wertVonTextEN}
                                onChange={(evt) => props.onChange(props.index, "wertVonTextEN", evt.target.value)}                            />
                        </div>
                        <div className="col">
                            <label htmlFor={"step" + props.index}>Skala bis (Text EN)</label>
                            <input
                                type="text"
                                id={"step" + props.index}
                                className="form-control form-control-md"
                                value={props.frage.wertBisTextEN}
                                onChange={(evt) => props.onChange(props.index, "wertBisTextEN", evt.target.value)}                            />
                        </div>
                    </div>
                </div>


            } else if (mode === "Visuelle Analogskala") {

            } else if (mode === "Checkbox") {
                return <div>
                    <h5>Daten für Checkbox</h5>
                    <div className="row">
                        <div className="col" style={{maxWidth: 30}}>
                            Nr.
                        </div>
                        <div className="col">
                            <label>Antwortmöglichkeit DE</label>
                        </div>
                        <div className="col">
                            <label>Antwortmöglichkeit EN</label>
                        </div>
                        <div className="col">
                            <label>DB Wert</label>
                        </div>
                        <div className="col" style={{maxWidth: 130}}>
                        </div>
                    </div>

                    {!!props.frage.checkboxes && props.frage.checkboxes.map((entry, arrIndex) => {
                        return <div className="row">
                            <div className="col" style={{maxWidth: 30}}>
                                {arrIndex + 1}.
                            </div>
                            <div className="col">
                                <input
                                    type="text"
                                    id={"checkbox" + arrIndex}
                                    className="form-control form-control-md"
                                    value={entry["titelDE"]}
                                    onChange={handleCheckboxTextChanged(arrIndex, "titelDE")}
                                />
                            </div>
                            <div className="col">
                                <input
                                    type="text"
                                    id={"checkbox" + arrIndex}
                                    className="form-control form-control-md"
                                    value={entry["titelEN"]}
                                    onChange={handleCheckboxTextChanged(arrIndex, "titelEN")}
                                />
                            </div>
                            <div className="col">
                                <input
                                    type="text"
                                    id={"checkbox" + arrIndex}
                                    className="form-control form-control-md"
                                    value={entry["dbWert"]}
                                    onChange={handleCheckboxTextChanged(arrIndex, "dbWert")}
                                />
                            </div>
                            <div className="col" style={{maxWidth: 130}}>
                                <MDBBtn color="outline-dark" onClick={() => {
                                    props.removeFromCheckBoxArray(props.index, entry.id)
                                }}>
                                    Entfernen
                                </MDBBtn>
                            </div>
                        </div>
                    })}

                    <div className="row">
                        <div className="col">
                            <MDBBtn color="outline-dark" onClick={() => props.addToCheckBoxArray(props.index)}>
                                Eintrag hinzufügen
                            </MDBBtn>
                        </div>
                    </div>

                </div>
            }
        }
    }

    const renderErrorField = (index, key) => {
        const found = hasError(index, key);
        if(!!found){
           return <span className="error">{found}</span>
        }
    }

    const hasError = (index, key) => {
        if(!!props.errors){
            const found = props.errors.find(e => e.index === index && e.key === key);
            if(!!found && !!found.error){
                return found["error"];
            }
        }
        return null;
    }

    const updateMode = (mode) => {
        setMode(mode);
        props.onChange(props.index, "modus", mode);
    }

    const updateTagesTyp = (type) => {
        props.onChange(props.index, "tagestyp", type);
    }

    return (
        <div className="frage">
            <div style={{position: "absolute", right: 10, top: 10}}>
                <MDBBtn color="outline-error" onClick={() => props.removeFrage(props.frage.id)}>Frage entfernen</MDBBtn>
            </div>
            <div className="title">Frage {props.index + 1}</div>
            <div className="row" style={{marginBottom: 0}}>
                <div className="col">
                    <label htmlFor={"fragede" + props.index}>Frage DE</label>
                    <input
                        type="text"
                        id={"fragede" + props.index}
                        className={"form-control form-control-md " + (!!hasError(props.index, "frageDE") && "error")}
                        value={props.frage.frageDE}
                        onChange={(evt) => props.onChange(props.index, "frageDE", evt.target.value)}
                    />
                    {renderErrorField(props.index, "frageDE")}
                </div>
            </div>
            <div className="row"  style={{marginTop: 5}}>
                <div className="col">
                    <label htmlFor={"frageen" + props.index}>Frage EN</label>
                    <input
                        type="text"
                        id={"frageen" + props.index}
                        className={"form-control form-control-md " + (!!hasError(props.index, "frageEN") && "error")}
                        value={props.frage.frageEN}
                        onChange={(evt) => props.onChange(props.index, "frageEN", evt.target.value)}
                    />
                    {renderErrorField(props.index, "frageEN")}
                </div>
            </div >

            <div className="row">
                <div className="col">
                    <label htmlFor={"btn" + props.index}>Modus</label>
                    <MDBDropdown id={"btn" + props.index} style={{fontSize: "14px"}}>
                        <MDBDropdownToggle caret className="drops">
                            {mode}
                        </MDBDropdownToggle>
                        <MDBDropdownMenu basic>
                            <MDBDropdownItem onClick={() => updateMode("Texteingabe")}>
                                Texteingabe
                            </MDBDropdownItem>
                            <MDBDropdownItem onClick={() => updateMode("Schieberegler")}>
                                Schieberegler
                            </MDBDropdownItem>
                            <MDBDropdownItem onClick={() => updateMode("Emojis")}>
                                Emojis
                            </MDBDropdownItem>
                            <MDBDropdownItem onClick={() => updateMode("Checkbox")}>
                                Checkbox
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </div>
                <div className="col">
                    <label htmlFor={"key" + props.index}>DB-Key</label>
                    <input
                        type="text"
                        id={"key" + props.index}
                        className="form-control form-control-md"
                        className={"form-control form-control-md " + (!!hasError(props.index, "db") && "error")}
                        value={props.frage.db}
                        onChange={(evt) => props.onChange(props.index, "db", evt.target.value)}
                    />
                    {renderErrorField(props.index, "db")}
                </div>
                <div className="col">
                    <label htmlFor={"required" + props.index}>Verpflichtend</label>
                    <input
                        type="checkbox"
                        id={"required" + props.index}
                        className="form-control form-control-md"
                        checked={!!props.frage.verpflichtend}
                        style={{width: 30}}
                        onChange={(evt) => props.onChange(props.index, "verpflichtend", !!!props.frage.verpflichtend)}
                    />
                </div>
                <div className="col">
                    <label htmlFor={"btn" + props.index}>Tageszeit</label>
                    <MDBDropdown id={"btn" + props.index} style={{fontSize: "14px"}}>
                        <MDBDropdownToggle caret className="drops">
                            {!!props.frage.tagestyp ? props.frage.tagestyp : "Egal"}
                        </MDBDropdownToggle>
                        <MDBDropdownMenu basic>
                            <MDBDropdownItem onClick={() => updateTagesTyp("Egal")}>
                                Egal
                            </MDBDropdownItem>
                            <MDBDropdownItem onClick={() => updateTagesTyp("Morgens")}>
                                Morgens
                            </MDBDropdownItem>
                            <MDBDropdownItem onClick={() => updateTagesTyp("Abends")}>
                                Abends
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </div>
            </div>

            {getInputFieldsBasedOnType()}
        </div>
    );
}
