import React, { useEffect } from "react"
import EditAdminUserModal from "./EditAdminUserModal";

export default function AdminUsersRow({item, active, onAfterSubmit}) {

    const getAddress = () => {
        if(!!item && item.rolle == "Retailer"){
            return <div>
                {!!item.adresse && <div>{item.adresse}</div>}
                {(!!item.plz && item.ort) && <div>{item.plz} {item.ort}</div>}
            </div>
        }
        return "-";
    }


  return (
    <tr className={active ? "tippsRow active" : "tippsRow"}>
        <td>{item.email}</td>
        <td>{item.vorname}</td>
        <td>{item.nachname}</td>
        <td>{item.rolle}</td>
        <td>{!!item.kette ? item.kette : '-'}</td>
        <td>{!!item.filiale ? item.filiale : '-'}</td>
        <td>{getAddress()}</td>
        <td
            style={{
              paddingRight: "15px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >

            <EditAdminUserModal
                item={item}
                onAfterSubmit={onAfterSubmit}
            />
              {/*<EditTipModal
              name={props.item.message}
              id={props.item.id}
              anzeige={props.item.anzeige}
              anlage={props.item.anlage}
              bewertung={props.item.bewertung}
              index={props.index}
              title={props.item.title}
              bewertungen={props.item.bewertungen}
              titleEn={props.item.titleEn}
              messageEn={props.item.messageEn}
              toggleLoading={() => props.toggleLoading()}
            />*/}
          </td>
    </tr>
  )
}
