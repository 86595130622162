import React from "react";
import { MDBBtn } from "mdbreact";
import { BsPerson } from "react-icons/bs";
import { MdCloudDownload } from "react-icons/md";

export default function BenutzerButton(props) {
  return (
    <a id="getAllUserBtn">
      <MDBBtn
        color="dark"
        className="btnExport"
        size="lg"
        onClick={props.onClick}
      >
        <BsPerson size={25} className="btnIcon" />
        Benutzer
        <MdCloudDownload size={25} className="btnIconDl" />
      </MDBBtn>
    </a>
  );
}
