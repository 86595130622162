import React, {useCallback, useEffect} from "react"
import "./index.css"
import { MDBBtn } from "mdbreact"
import ZeitraumButton from "../Home/ZeitraumButton"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import StatistikBox from "../Home/StatistikBox"
import AddUserModal from "./AddUserModal"
import { db, firebase } from "../Firebase"
import BenutzerAnzeigeBtn from "./BenutzerAnzeigeBtn.jsx"
import * as moment from "moment"
import { AiOutlineArrowUp } from "react-icons/ai"
import { AiOutlineArrowDown } from "react-icons/ai"
import format from "date-fns/format"
import CircularProgress from "@material-ui/core/CircularProgress"
import jstat from "jstat"
import DataTable from "react-data-table-component"
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsRight,
  FiChevronsLeft,
} from "react-icons/fi"
import { MdClose } from "react-icons/md"
import YearPicker from "rc-year-picker"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import FooterBox from "../FooterBox"

import { isAfter, isSameDay, isBefore } from "date-fns"
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact"
import de from "date-fns/locale/de"
import getYear from "date-fns/getYear"
import DatePicker, { registerLocale } from "react-datepicker"
import {debounce, getCountryOptions, getLoggedInUser} from "../../utils/globals";
import RetailerSelect from "../Common/RetailerSelect";
registerLocale("de", de)

var numbers = /^[0-9]+$/
var letters = /^[a-zA-ZäüöÄÜÖ\s]*$/

export default function Benutzerscreen() {
  const [role, setRole] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState(null);

  const [allData, setAllData] = React.useState([])
  const [userData, setUserData] = React.useState()
  const [selected, setSelected] = React.useState()
  const [fragen, setFragen] = React.useState([]);

  const [von, setVon] = React.useState()
  const [bis, setBis] = React.useState()
  const [noData, setNoData] = React.useState(true)
  const [gb, setGb] = React.useState(new Date())
  const [logCode, setLogCode] = React.useState("")

  const [lautstärkeData, setLautstärkeData] = React.useState(false)
  const [belastungData, setBelastungData] = React.useState(false)
  const [kieferData, setKieferData] = React.useState(false)
  const [nackenData, setNackenData] = React.useState(false)
  const [stimData, setStimData] = React.useState(false)
  const [stressData, setStressData] = React.useState(false)
  const [schlafData, setSchlafData] = React.useState(false)


  const [vorname, setVorname] = React.useState("")
  const [nachname, setNachname] = React.useState("")
  const [plz, setPlz] = React.useState("")
  const [land, setLand] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [adresse, setAdresse] = React.useState("")
  const [ort, setOrt] = React.useState("")
  const [retailer, setRetailer] = React.useState("")
  const [showFlash, setShowFlash] = React.useState(false)
  const [flashMessage, setFlashMessage] = React.useState("")
  const [flashType, setFlashType] = React.useState("")
  const [changedPw, setChangedPw] = React.useState(false)
  const [newPw, setNewPw] = React.useState("")
  const [userSearch, setUserSearch] = React.useState("")
  const [landFilter, setLandFilter] = React.useState("Land")
  const [gbFilter, setGbFilter] = React.useState("Geburtsjahr")
  const [aufsteigend, setAufsteigend] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [userDataLoading, setUserDataLoading] = React.useState(false)

  const [belLine, setBelLine] = React.useState()
  const [lautLine, setLautLine] = React.useState()
  const [kieferLine, setKieferLine] = React.useState()
  const [nackenLine, setNackenLine] = React.useState()
  const [stressLine, setStressLine] = React.useState()
  const [stimLine, setStimLine] = React.useState()
  const [schlafLine, setSchlafLine] = React.useState()
  const [schwindelLine, setSchwindelLine] = React.useState([])
  const [hyperLine, setHyperLine] = React.useState([])
  const [migraeneLine, setMigraeneLine] = React.useState([])

  const [availablePages, setAvailablePages] = React.useState(1)
  const [page, setPage] = React.useState(1)
  const [filterEveryUser, setFilterEveryUser] = React.useState([])
  const [bemerkungen, setBemerkungen] = React.useState([])
  const [anzeigeData, setAnzeigeData] = React.useState([])
  const [forgTinSeite, setForgTinSeite] = React.useState([])


  const columns = [
    {
      name: "Datum",
      selector: "date",
      sortable: true,
    },

    {
      name: "Uhrzeit",
      selector: "uhrzeit",
      sortable: true,
    },
    {
      name: "Notiz",
      selector: "bemerkung",
      sortable: true,
    },
  ]
  const columnsForgTin = [
    {
      name: "Datum",
      selector: "date",
      sortable: true,
    },

    {
      name: "Uhrzeit",
      selector: "uhrzeit",
      sortable: true,
    },
    {
      name: "ForgTin Seite",
      selector: "seite",
      sortable: true,
    },
  ]

  const columnsTextData = [
    {
      name: "Datum",
      selector: "date",
      sortable: true,
    },

    {
      name: "Uhrzeit",
      selector: "uhrzeit",
      sortable: true,
    },
    {
      name: "Wert",
      selector: "wert",
      sortable: true,
    },
  ]


  useEffect(() => {
    moment.locale("de")
    getLoggedInUser().then(res => {
      if(!!res){
        setCurrentUser(res);
        setRole(!!res.rolle ? res.rolle : "Benutzer");
      }
    })
  }, []);

  useEffect(() => {
    if(!!role && !!currentUser){
      getAllUsers();
      deleteFilter()
      readFragen();
    }
  }, [role && currentUser]);

  useEffect(() => {
    checkSort()
  }, [aufsteigend])

  const readFragen = () => {
    db.collection("Admin")
        .doc("admin")
        .get()
        .then((doc) => {
          if (doc.exists) {
            const dbData = doc.data()
            setFragen(dbData["FragenNEU"]);
          }
        });
  }

  const checkSort = () => {
    if (aufsteigend === false) {
      const temp = filterEveryUser.sort((a, b) => {
        const fullNameA = a.data.nachname.toLowerCase()
        const fullNameB = b.data.nachname.toLowerCase()

        if (fullNameA < fullNameB) {
          return -1
        }
        if (fullNameB < fullNameA) {
          return 1
        }
        return 0
      })

      // setFilterEveryUser(temp);
      //vlt timeout weil diese methode benutzt filtereveryuser und hatt nich die oben geönderten werte
      checkPage(temp)
    } else if (aufsteigend) {
      const temp = filterEveryUser.sort((a, b) => {
        const fullNameA = a.data.nachname.toLowerCase()
        const fullNameB = b.data.nachname.toLowerCase()

        if (fullNameA > fullNameB) {
          return -1
        }
        if (fullNameB > fullNameA) {
          return 1
        }
        return 0
      })

      // setFilterEveryUser(temp);
      //vlt timeout weil diese methode benutzt filtereveryuser und hatt nich die oben geönderten werte
      checkPage(temp)
    }
  }

  const doTheFilter = () => {
    if (allData.length > 0) {
      const filteredData = allData.filter((item) => {
        const searchTrue = userSearch !== "" ? true : false
        const landTrue = landFilter !== "Land" ? true : false
        const gbTrue = gbFilter !== "Geburtsdatum" ? true : false
        let fullName = item.data.vorname + " " + item.data.nachname

        if (searchTrue && landTrue && gbTrue) {
          return fullName.toLowerCase().includes(userSearch.toLowerCase()) &&
          item.data.land === landFilter &&
          item.data.geburtsdatum != ""
              ? getYear(item.data.geburtsdatum.toDate()) === gbFilter
              : null
        } else if (!searchTrue && landTrue && gbTrue) {
          return item.data.land === landFilter && item.data.geburtsdatum != ""
              ? getYear(item.data.geburtsdatum.toDate()) === gbFilter
              : null
        } else if (!searchTrue && !landTrue && gbTrue) {
          return item.data.geburtsdatum != ""
              ? getYear(item.data.geburtsdatum.toDate()) === gbFilter
              : null
        } else if (searchTrue && landTrue && !gbTrue) {
          return (
              fullName.toLowerCase().includes(userSearch.toLowerCase()) &&
              item.data.land === landFilter
          )
        } else if (searchTrue && !landTrue && gbTrue) {
          return fullName.toLowerCase().includes(userSearch.toLowerCase()) &&
          item.data.geburtsdatum != ""
              ? getYear(item.data.geburtsdatum.toDate()) === gbFilter
              : null
        } else if (!searchTrue && landTrue && !gbTrue) {
          return item.data.land === landFilter
        } else if (searchTrue && !landTrue && !gbTrue) {
          return fullName.toLowerCase().includes(userSearch.toLowerCase())
        } else {
          return true
        }
      })
      if (filteredData.length <= 8) {
        setPage(1)
      }

      setUserData(filteredData[0])
      setFilterEveryUser(filteredData)
      checkPage(filteredData) // new
    }
  };

  useEffect(() => {
    doTheFilter();
  }, [landFilter, gbFilter])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      doTheFilter();
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [userSearch])

  useEffect(() => {
    checkPage(filterEveryUser)
  }, [page])

  const checkPage = (users) => {
    const von = page === 1 ? 0 : page * 8 - 8
    let bis = page * 8
    if (bis > users.length) {
      bis = users.length
    }

    const filteredPage = users.slice(von, bis)
    setAnzeigeData(filteredPage)
  }

  const deleteFilter = () => {
    setLandFilter("Land")
    setUserSearch("")
    setGbFilter("Geburtsdatum")
    if(document.getElementsByClassName("input-icon-close").length > 0){
      document.getElementsByClassName("input-icon-close")[0].click()
    }
    setAufsteigend(false)
    setSelected(allData[0])
    setFilterEveryUser(allData)
    checkPage(allData) // new
  }

  useEffect(() => {
    setAvailablePages(Math.ceil(filterEveryUser.length / 8))
  }, [filterEveryUser])

  useEffect(() => {
    if (von && bis) {
      calculateData()
    }
  }, [von, bis])

  // useEffect(() => {
  //   if (allData !== undefined && allData.length > 0 && !selected) {
  //     setUserData(allData[0]);
  //   } else {
  //     setUserData(selected);
  //   }
  // }, [allData]);

  useEffect(() => {
    updateUserData()
  }, [userData])

  const updateUserData = () => {
    calculateData()

    if (userData) {
      setVorname(userData.data.vorname)
      setNachname(userData.data.nachname)
      setEmail(userData.data.email)
      setPlz(userData.data.plz)
      setOrt(userData.data.ort)

      setAdresse(userData.data.adresse)
      setLand(userData.data.land)
      setNewPw("")
      setRetailer(userData.data.retailer);
      setLogCode(userData.data.logCode);

      try {
        setGb(moment(userData.data.geburtsdatum.toDate()).startOf('day').format())
      } catch {
        setGb("")
      }
    }
  }

  const setDates = (startDate, endDate) => {
    setVon(startDate)
    setBis(endDate)
  }

  const removeEmptyValues = (values) => {
    return values.filter(value => {
      return value.length == 2 && value[1] !== undefined;
    })
  }

  function regression(arrWeight, arrHeight) {
    let r, sy, sx, b, a, meanX, meanY
    r = jstat.corrcoeff(arrHeight, arrWeight)
    sy = jstat.stdev(arrWeight)
    sx = jstat.stdev(arrHeight)
    meanY = jstat(arrWeight).mean()
    meanX = jstat(arrHeight).mean()
    b = r * (sy / sx)
    a = meanY - meanX * b
    //Set up a line
    let y1, y2, x1, x2
    x1 = jstat.min(arrHeight)
    x2 = jstat.max(arrHeight)
    y1 = a + b * x1
    y2 = a + b * x2


    if(y1 < 0){
      y1 = 0;
    }
    if(y2 < 0){
      y2 = 0;
    }

    return {
      line: [
        [x1, y1],
        [x2, y2],
      ],
      r,
    }
  }

  const getValueFromFragen = (key, value) => {
    if(!!fragen){
      const entry = fragen.find(x => x.db == key);
      if(!!entry && !!entry.checkboxes){
          const found = entry.checkboxes.find(cb => cb.dbWert == value);
          if(!!found){
            return found.titelDE;
          }
      }
    }
    return "-";
  }

  const calculateData = () => {
    if (
      userData !== undefined &&
      userData.eintraege &&
      userData.eintraege.length > 0
    ) {
      let filteredData
      if (von && bis) {
        filteredData = userData.eintraege.filter(
          (eintrag) =>
            (isAfter(eintrag.eingetragen.toDate(), von) &&
              isBefore(eintrag.eingetragen.toDate(), bis)) ||
            isSameDay(eintrag.eingetragen.toDate(), von) ||
            isSameDay(eintrag.eingetragen.toDate(), bis)
        )
      } else {
        filteredData = userData.eintraege
      }

      const groupByDate = (array) => {
        return array;
        /*return array.reduce((results, item) => {
          const current = results.find((i) =>
              isSameDay(i.eingetragen.toDate(), item.eingetragen.toDate())
          )
          if (current) {
            for (let property in item) {
//              if (property !== "eingetragen") {
                if (property === "lautstaerke") {
                  current[property] = (item[property] + current[property]) / 2
                }
  //            }
            }
          } else {
            results.push({ ...item })
          }
          return results
        }, [])*/
      }


      //calculates de dates and groups them
      const dateFilteredData = groupByDate(filteredData)
      const lautstärke =
        dateFilteredData !== undefined && dateFilteredData.length > 0
          ? dateFilteredData.map((eintrag, index) => {
              return [
                eintrag.eingetragen.toDate().getTime() + index * 1000,
                eintrag.lautstaerke,
              ]
            })
          : [0]

      //regression used
      const tempLautX = []
      const tempLautY = []
      lautstärke.forEach((eintrag) => {
        if(eintrag[1] !== undefined){
          tempLautX.push(eintrag[0])
          tempLautY.push(eintrag[1])
        }
      })

      setLautLine(regression(tempLautY, tempLautX))
      setLautstärkeData(lautstärke)

      //belastung
      const belastung =
        dateFilteredData !== undefined && dateFilteredData.length > 0
          ? dateFilteredData.map((eintrag, index) => {
              return [
                eintrag.eingetragen.toDate().getTime() + index * 1000,
                eintrag.belastung,
              ]
            })
          : [0]

      //regression used
      const tempBelX = []
      const tempBelY = []
      belastung.forEach((eintrag) => {
        if(eintrag[1] !== undefined){
          tempBelX.push(eintrag[0])
          tempBelY.push(eintrag[1])
        }
      })

      setBelLine(regression(tempBelY, tempBelX))
      setBelastungData(belastung)

      //kieferVerspannung
      const kieferVerspannung =
        dateFilteredData !== undefined && dateFilteredData.length > 0
          ? dateFilteredData.map((eintrag, index) => {
              return [
                eintrag.eingetragen.toDate().getTime() + index * 1000,
                eintrag.kieferVerspannung,
              ]
            })
          : [0]

      //regression used
      const tempKiefX = []
      const tempKiefY = []
      kieferVerspannung.forEach((eintrag) => {
        if(eintrag[1] !== undefined){
          tempKiefX.push(eintrag[0])
          tempKiefY.push(eintrag[1])
        }

      })

      setKieferLine(regression(tempKiefY, tempKiefX))
      setKieferData(kieferVerspannung)

      //nackenVerspannung
      const nackenVerspannung =
        dateFilteredData !== undefined && dateFilteredData.length > 0
          ? dateFilteredData.map((eintrag, index) => {
              return [
                eintrag.eingetragen.toDate().getTime() + index * 1000,
                eintrag.nackenVerspannung,
              ]
            })
          : [0]

      //regression used
      const tempNackX = []
      const tempNackY = []
      nackenVerspannung.forEach((eintrag) => {
        if(eintrag[1] !== undefined){
          tempNackX.push(eintrag[0])
          tempNackY.push(eintrag[1])
        }
      })

      setNackenLine(regression(tempNackY, tempNackX))
      setNackenData(nackenVerspannung)

      //schlaf
      const schlaf =
          dateFilteredData !== undefined && dateFilteredData.length > 0
              ? dateFilteredData.map((eintrag, index) => {
                return [
                  eintrag.eingetragen.toDate().getTime() + index * 1000,
                  eintrag.schlaf,
                ]
              })
              : [0]

      //regression used
      const tempSchlafX = []
      const tempSchlafY = []
      schlaf.forEach((eintrag) => {
        if(eintrag[1] !== undefined){
          tempSchlafX.push(eintrag[0])
          tempSchlafY.push(eintrag[1])
        }
      })

      setSchlafLine(regression(tempSchlafY, tempSchlafX))
      setSchlafData(schlaf)


      //Stimmungslage
      const stimmungslage =
        dateFilteredData !== undefined && dateFilteredData.length > 0
          ? dateFilteredData.map((eintrag, index) => {
              return [
                eintrag.eingetragen.toDate().getTime() + index * 1000,
                eintrag.stimmungslage,
              ]
            })
          : [0]

      //regression used
      const tempStimmX = []
      const tempStimmY = []
      stimmungslage.forEach((eintrag) => {
        if(eintrag[1] !== undefined){
          tempStimmX.push(eintrag[0])
          tempStimmY.push(eintrag[1])
        }
      })

      setStimLine(regression(tempStimmY, tempStimmX))
      setStimData(stimmungslage)

      //stress
      const stress =
        dateFilteredData !== undefined && dateFilteredData.length > 0
          ? dateFilteredData.map((eintrag, index) => {
              return [
                eintrag.eingetragen.toDate().getTime() + index * 1000,
                eintrag.stress,
              ]
            })
          : [0]

      //regression used
      const tempStressX = []
      const tempStressY = []
      stress.forEach((eintrag) => {
        if(eintrag[1] !== undefined){
          tempStressX.push(eintrag[0])
          tempStressY.push(eintrag[1])
        }
      })

      setStressLine(regression(tempStressY, tempStressX))
      setStressData(stress)

      //ForgTin Seite
      const forgTinSeite =
        dateFilteredData !== undefined && dateFilteredData.length > 0
          ? dateFilteredData.map((eintrag, index) => {
              return {
                id: index,
                seite: eintrag.forgTinBeide
                  ? "Beide Seiten"
                  : eintrag.forgTinLinks
                  ? "Links"
                  : eintrag.forgTinRechts
                  ? "Rechts"
                  : "Keine Seite",
                uhrzeit: format(eintrag.eingetragen.toDate(), "HH:mm"),
                date: format(eintrag.eingetragen.toDate(), "d.M.yyyy"),
              }
            })
          : [0]

      setForgTinSeite(forgTinSeite)

      //bemerkungen
      const bemerkungen =
        dateFilteredData !== undefined && dateFilteredData.length > 0
          ? dateFilteredData.map((eintrag, index) => {
              return {
                id: index,
                bemerkung: eintrag.bemerkung,
                uhrzeit: format(eintrag.eingetragen.toDate(), "HH:mm"),
                date: format(eintrag.eingetragen.toDate(), "d.M.yyyy"),
              }
            })
          : [0]

      const filteredBemerkungen = bemerkungen.filter(
        (bemerkung) => bemerkung.bemerkung !== ""
      )

      setBemerkungen(filteredBemerkungen)



      //Schwindel
      const schwindel =
          dateFilteredData !== undefined && dateFilteredData.length > 0
              ? dateFilteredData.map((eintrag, index) => {
                return {
                  id: index,
                  wert: getValueFromFragen("schwindel", eintrag.schwindel),
                  uhrzeit: format(eintrag.eingetragen.toDate(), "HH:mm"),
                  date: format(eintrag.eingetragen.toDate(), "d.M.yyyy"),
                }
              })
              : [0]

      setSchwindelLine(schwindel)

      //Hyper
      const hyper =
          dateFilteredData !== undefined && dateFilteredData.length > 0
              ? dateFilteredData.map((eintrag, index) => {
                return {
                  id: index,
                  wert: getValueFromFragen("hyperakusis", eintrag.hyperakusis),
                  uhrzeit: format(eintrag.eingetragen.toDate(), "HH:mm"),
                  date: format(eintrag.eingetragen.toDate(), "d.M.yyyy"),
                }
              })
              : [0]

      setHyperLine(hyper)

      //Migräne
      const migraene =
          dateFilteredData !== undefined && dateFilteredData.length > 0
              ? dateFilteredData.map((eintrag, index) => {
                return {
                  id: index,
                  wert: getValueFromFragen("migraene", eintrag.migraene),
                  uhrzeit: format(eintrag.eingetragen.toDate(), "HH:mm"),
                  date: format(eintrag.eingetragen.toDate(), "d.M.yyyy"),
                }
              })
              : [0]

      setMigraeneLine(migraene)


      //durchschnitt
      // const durchschnitt =
      //   filteredData !== undefined && filteredData.length > 0
      //     ? filteredData.map((eintrag, index) => {
      //         return [
      //           eintrag.eingetragen.toDate().getTime() + index * 1000,
      //           eintrag.belastung,
      //         ];
      //       })
      //     : [0];

      // setBelastungData(belastung);
      setNoData(false)
    } else {
      setNoData(true)
      setLautstärkeData([0])
      setBelastungData([0])
      // setDurchschnitt([0])
    }
  }

  const setData = (data) => {
    setUserData(data)
    setSelected(data)
    updateUserData()
  }

  useEffect(() => {
    if (filterEveryUser.length > 0) {
      checkSort()
      if (selected) {
        //hier wird falscher user ausgewählt eig sollte jeder sortiert in einem array sein und dann rausgepickt werden können
        const getIndex = anzeigeData.findIndex(
          (item) => item.id === selected.id
        )

        const button = document.getElementsByClassName("btnBenutzer")[getIndex]

        if (button) {
          button.click()
        }
      } else {
        const button = document.getElementById(filterEveryUser[0].id)
        setUserData(filterEveryUser[0])
        if (button) {
          button.click()
        }
      }
    }
  }, [allData])

  const filterFirebaseEntries = (entry) => {
    return !!!entry.rolle;
  }

  const getAllUsers = async () => {
    const neu = []
    setUserDataLoading(true);
    let query = db.collection("Benutzer");
    if(role == "Benutzer"){
      query = query.where("data.email", "==", currentUser.email)
    } else if(role == "Retailer"){
      query = query.where("data.retailer", "==", currentUser.uid)
    }

    const test = await query.get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc, index) {
          const data = doc.data()["data"];
          if(!!filterFirebaseEntries(data)){
            neu.push({
              data,
              id: doc.id,
              eintraege: doc.data()["eintraege"],
              index: index,
            })
          }
        })
        return neu
      }).catch(e => {
        console.error('e: ', e);
        })
    setFilterEveryUser(test)
    setAllData(test)
    setUserDataLoading(false);
  }

  useEffect(() => {
    if (newPw !== "") {
      setChangedPw(true)
    } else {
      setChangedPw(false)
    }
  }, [newPw])

  const saveChanges = () => {

    confirmAlert({
      title: "Änderungen speichern",
      message: "Sind Sie sicher, dass Sie diese Änderungen speichern wollen?",
      buttons: [
        {
          label: "Änderungen speichern",
          onClick: () => {

            let _gb = !!gb ? moment(gb).toDate() : null;
            if (
              vorname &&
              nachname &&
              email &&
              plz &&
              ort &&
              land &&
              adresse &&
              _gb &&
              ort.match(letters) &&
              plz.match(numbers)
            ) {
              setLoading(true)

              if (changedPw === true) {
                if (newPw !== "") {
                  const xhr = new XMLHttpRequest()

                  xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                      if (xhr.status === 200) {
                        if (
                          xhr.responseText === "Changed Password successfully!"
                        ) {
                          db.collection("Benutzer")
                            .doc(userData.id)
                            .update({
                              "data.vorname": vorname,
                              "data.nachname": nachname,
                              "data.ort": ort,
                              "data.adresse": adresse,
                              "data.email": email,
                              "data.plz": plz,
                              "data.land": land,
                              "data.geburtsdatum": _gb,
                              "data.retailer": !!retailer ? retailer : null,
                            })
                            .then(() => {
                              const curr = userData
                              curr.data.vorname = vorname
                              curr.data.nachname = nachname
                              curr.data.ort = ort
                              curr.data.adresse = adresse
                              curr.data.email = email
                              curr.data.plz = plz
                              curr.data.land = land
                              curr.data.gb = !!_gb ? moment(_gb).startOf('day').format() : null
                              curr.data.geburtsdatum = !!_gb ?  moment(_gb) : null;
                              curr.data.retailer = !!retailer ? retailer : null

                              setData(curr)
                              getAllUsers()
                              setLoading(false)
                              setFlashMessage(
                                "Benutzerdaten wurden erfolgreich aktualisiert!"
                              )
                              setFlashType("success")
                              setShowFlash(true)
                            })
                        }
                      } else {
                        setFlashMessage(xhr.responseText)
                        setFlashType("error")
                        setShowFlash(true)
                        setLoading(false)
                      }
                    }
                  }

                  xhr.open(
                    "get",
                    "https://europe-west3-forgtin-62d8f.cloudfunctions.net/manageUser?userId=" +
                      userData.id +
                      "&mode=updatePassword&newPw=" +
                      newPw +
                      "&email=" +
                      email,
                    true
                  )
                  xhr.send()
                } else {
                  setLoading(false)
                  alert("Neues Passwort ist ungültig!")
                }
              } else {
                db.collection("Benutzer")
                  .doc(userData.id)
                  .update({
                    "data.vorname": vorname,
                    "data.nachname": nachname,
                    "data.ort": ort,
                    "data.adresse": adresse,
                    "data.email": email,
                    "data.plz": plz,
                    "data.land": land,
                    "data.geburtsdatum": _gb,
                    "data.retailer": !!retailer ? retailer : null
                  })
                  .then(() => {
                    const curr = userData
                    curr.data.vorname = vorname
                    curr.data.nachname = nachname
                    curr.data.ort = ort
                    curr.data.adresse = adresse
                    curr.data.email = email
                    curr.data.plz = plz
                    curr.data.land = land
                    curr.data.gb = !!_gb ?  moment(_gb).startOf('day').format() : null
                    curr.data.geburtsdatum = !!_gb ?  moment(_gb) : null;
                    curr.data.retailer = !!retailer ? retailer : null
                    setData(curr)
                    getAllUsers()
                    setLoading(false)
                    setFlashMessage(
                      "Benutzerdaten wurden erfolgreich aktualisiert!"
                    )
                    setFlashType("success")
                    setShowFlash(true)
                  })
              }
            } else {
              alert("Bitte alle Felder ausfüllen!")
            }
          },
        },
        {
          label: "Abbrechen",
          onClick: () => {
            setSelected(userData)

            getAllUsers()
          },
        },
      ],
    })
  }

  const clearSelected = () => {
    setSelected(null)
  }

  const handleUserData = () => {
    if (filterEveryUser.length > 0) {
      try {
        const all = document.getElementsByClassName("activeBtn")
        if (all.length > 0) {
          for (var i = 0; i < all.length; i++) {
            all.item(i).classList.remove("activeBtn")
          }
        }

        document.getElementById(userData.id).classList.add("activeBtn")
      } catch {}

      const lockUser = () => {
        db.collection("Benutzer")
          .doc(userData.id)
          .update({
            "data.gesperrt": true,
          })
          .then(() => {
            const curr = userData
            curr.data.gesperrt = true
            setData(curr)
          })
      }

      const deleteUser = () => {
        confirmAlert({
          title: "Benutzer löschen",
          message: "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?",
          buttons: [
            {
              label: "Benutzer löschen ",
              onClick: () => {
                setLoading(true)
                const xhr = new XMLHttpRequest()

                xhr.onreadystatechange = () => {
                  if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                      if (xhr.responseText === "Deletion Success") {
                        db.collection("Benutzer")
                          .doc(userData.id)
                          .delete()
                          .then(() => {
                            //hier eventuell useEffect callvback frp setSelected
                            if (userData.id !== allData[0].id) {
                              setSelected(allData[0])
                              setUserData(allData[0])
                            } else {
                              setSelected(allData[1])
                              setUserData(allData[1])
                            }

                            getAllUsers()
                            setLoading(false)
                            setFlashMessage(
                              "Benutzer wurde erfolgreich gelöscht"
                            )
                            setFlashType("success")
                            setShowFlash(true)
                          })
                      }
                    } else {
                      setFlashMessage(xhr.responseText)
                      setFlashType("error")
                      setShowFlash(true)
                      setLoading(false)
                    }
                  }
                }

                xhr.open(
                  "get",
                  "https://europe-west3-forgtin-62d8f.cloudfunctions.net/manageUser?userId=" +
                    userData.id +
                    "&mode=delete",
                  true
                )
                xhr.send()
              },
            },
            {
              label: "Abbrechen",
              onClick: () => {},
            },
          ],
        })
      }

      const changeNewPw = () => {
        setChangedPw(true)
        var length = 16,
          charset =
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
          code = ""
        for (var i = 0, n = charset.length; i < length; ++i) {
          code += charset.charAt(Math.floor(Math.random() * n))
        }
        setNewPw(code)
      }

      const deLockUser = () => {
        db.collection("Benutzer")
          .doc(userData.id)
          .update({
            "data.gesperrt": false,
          })
          .then(() => {
            const curr = userData
            curr.data.gesperrt = false
            setData(curr)
            getAllUsers()
          })
      }
      return (
        <div>
          {loading && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255,255,255,0.7)",
                zIndex: 9999,
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <CircularProgress />
            </div>
          )}{" "}
          <div className="topCointainer">
            {userData.data.gesperrt && (
              <h4 style={{ color: "red" }}>Benutzer gesperrt!</h4>
            )}
            <Row>
              <Col>
                <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <MDBBtn
                    color="white"
                    className="classicBtn"
                    style={{ minWidth: "92px" }}
                    disabled={userData.data.gesperrt ? true : false}
                    onClick={changeNewPw}
                  >
                    Passwort generieren
                  </MDBBtn>
                </Col>
                <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <input
                    type={newPw ? "text" : "password"}
                    id="passwort"
                    className="form-control form-control-md pwInput"
                    value={newPw || "asdagsdfg123512"}
                    disabled={false}
                    style={{ marginLeft: 0, width: "100%" }}
                    autoComplete="off"
                    onChange={(event) => setNewPw(event.target.value)}
                  />
                </Col>
              </Col>
              {(role === "Administrator" || role === "Retailer" || role === "Mitarbeiter") &&
                  <Col>
                    <MDBBtn
                        color="white"
                        className="classicBtn mr-auto"
                        onClick={userData.data.gesperrt ? deLockUser : lockUser}
                    >
                      {userData.data.gesperrt
                          ? "Benutzer entsperren"
                          : "Benutzer sperren"}
                    </MDBBtn>
                    <Col style={{ paddingLeft: 0 }}>
                      <MDBBtn
                          color="white"
                          className="classicBtn mr-auto"
                          onClick={deleteUser}
                      >
                        Benutzer löschen
                      </MDBBtn>
                    </Col>
                  </Col>
              }

            </Row>

            <Row>
              <Col>
                <label htmlFor="vorname">Vorname</label>
                <input
                  type="text"
                  id="vorname"
                  className="form-control form-control-md"
                  value={vorname}
                  onChange={(event) => setVorname(event.target.value)}
                  disabled={userData.data.gesperrt ? true : false}
                />
              </Col>
              <Col>
                <label htmlFor="nachname">Nachname</label>
                <input
                  type="text"
                  id="nachname"
                  className="form-control form-control-md"
                  value={nachname}
                  onChange={(event) => setNachname(event.target.value)}
                  disabled={userData.data.gesperrt ? true : false}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="email">E-Mail</label>
                <input
                  type="text"
                  id="email"
                  className="form-control form-control-md"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  disabled={true/*userData.data.gesperrt ? true : false*/}
                />
              </Col>
              <Col>
                <label htmlFor="adresse">Adresse</label>
                <input
                  type="text"
                  id="adresse"
                  className="form-control form-control-md"
                  value={adresse}
                  onChange={(event) => setAdresse(event.target.value)}
                  disabled={userData.data.gesperrt ? true : false}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {!plz.match(numbers) && plz != "" && (
                  <p style={{ color: "red", fontSize: 14 }}>
                    Bitte nur Zahlen eingeben!
                  </p>
                )}
                <label htmlFor="plz">PLZ</label>
                <input
                  type="text"
                  id="plz"
                  className="form-control form-control-md"
                  value={plz}
                  onChange={(event) => setPlz(event.target.value)}
                  disabled={userData.data.gesperrt ? true : false}
                />
              </Col>
              <Col>
                {!ort.match(letters) && ort != "" && (
                  <p style={{ color: "red", fontSize: 14 }}>
                    Bitte nur Buchstaben eingeben!
                  </p>
                )}
                <label htmlFor="ort">Ort</label>
                <input
                  type="text"
                  id="ort"
                  className="form-control form-control-md"
                  value={ort}
                  onChange={(event) => setOrt(event.target.value)}
                  disabled={userData.data.gesperrt ? true : false}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="land">Land</label>

                <MDBDropdown id={"land"} style={{ fontSize: "14px" }}>
                  <MDBDropdownToggle caret className="drops">
                    {land}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu basic>
                    {getCountryOptions(setLand)}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </Col>
              <Col>
                <label htmlFor="gb">Geburtsdatum</label>
                <div>
                  <DatePicker
                      id="gb"
                      selected={!!gb ? moment(gb).toDate() : undefined}
                      onChange={(date) => setGb(date)}
                      locale="de"
                      dateFormat={"dd.MM.yyyy"}
                  />
                </div>

              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="logCode">Login-Code</label>
                <input
                    type="text"
                    id="logCode"
                    className="form-control form-control-md"
                    value={logCode}
                    disabled={true}
                />
              </Col>
              <Col>
              </Col>
            </Row>
            {role == "Administrator" &&
                <Row>
                  <Col sm={6}>
                    <label htmlFor="retailer">Retailer</label>
                    <RetailerSelect id="retailer" onChange={setRetailer} value={retailer}/>
                  </Col>
                </Row>
            }

            <MDBBtn
              color="dark"
              disabled={userData.data.gesperrt ? true : false}
              onClick={saveChanges}
            >
              Änderungen speichern
            </MDBBtn>
          </div>
          <div className="bottomContainer">
            <h5>
              Statistiken <br />
            </h5>

            {userData &&
            userData.eintraege &&
            userData.eintraege.length > 0 &&
            noData == false ? (
              <div>
                <p>Zeitraum wählen:</p>
                <ZeitraumButton
                  setDates={setDates}
                  startDate={moment(userData.eintraege[0].eingetragen.toDate())}
                  endDate={moment(new Date())}
                  // endDate={moment(
                  //   userData.eintraege[
                  //     userData.eintraege.length - 1
                  //   ].eingetragen.toDate()
                  // )}
                  startId={"neewone"}
                  endId={"neia"}
                />
                <div className="statContainer" style={{ paddingBottom: 20 }}>
                  {lautstärkeData && (
                    <StatistikBox
                      index={1}
                      zusatz="Lautstärke"
                      data={removeEmptyValues(lautstärkeData)}
                      data2={lautLine.line}
                      show={true}
                      name="Lautstärke"
                      linear={true}
                      user={
                        userData.data.vorname + "_" + userData.data.nachname
                      }
                      exportDisabled={role === "Retailer"}
                    />
                  )}
                  {belastungData && (
                    <StatistikBox
                      index={2}
                      zusatz="Belastung"
                      data={removeEmptyValues(belastungData)}
                      data2={belLine.line}
                      show={true}
                      name="Belastung"
                      linear={true}
                      user={
                        userData.data.vorname + "_" + userData.data.nachname
                      }
                      exportDisabled={role === "Retailer"}
                    />
                  )}
                  {nackenData && (
                    <StatistikBox
                      index={3}
                      zusatz="Nacken Verspannung"
                      data={removeEmptyValues(nackenData)}
                      data2={nackenLine.line}
                      show={true}
                      name="Nacken Verspannung"
                      linear={true}
                      user={
                        userData.data.vorname + "_" + userData.data.nachname
                      }
                      exportDisabled={role === "Retailer"}
                    />
                  )}
                  {kieferData && (
                    <StatistikBox
                      index={4}
                      zusatz="Kiefer Verspannung"
                      data={removeEmptyValues(kieferData)}
                      data2={kieferLine.line}
                      show={true}
                      name="Kiefer Verspannung"
                      linear={true}
                      user={
                        userData.data.vorname + "_" + userData.data.nachname
                      }
                      exportDisabled={role === "Retailer"}
                    />
                  )}
                  {stimData && (
                    <StatistikBox
                      index={5}
                      zusatz="Stimmungslage"
                      data={removeEmptyValues(stimData)}
                      data2={stimLine.line}
                      show={true}
                      name="Stimmungslage"
                      linear={true}
                      user={
                        userData.data.vorname + "_" + userData.data.nachname
                      }
                      exportDisabled={role === "Retailer"}
                    />
                  )}
                  {stressData && (
                    <StatistikBox
                      index={6}
                      zusatz="Stress"
                      data={removeEmptyValues(stressData)}
                      data2={stressLine.line}
                      show={true}
                      name="Stress"
                      linear={true}
                      user={
                        userData.data.vorname + "_" + userData.data.nachname
                      }
                      exportDisabled={role === "Retailer"}
                    />
                  )}

                  {schlafData && (
                      <StatistikBox
                          index={7}
                          zusatz="Schlaf"
                          data={removeEmptyValues(schlafData)}
                          data2={schlafLine.line}
                          show={true}
                          name="Schlaf"
                          linear={true}
                          user={
                            userData.data.vorname + "_" + userData.data.nachname
                          }
                          exportDisabled={role === "Retailer"}
                      />
                  )}

                  {forgTinSeite.length !== 0 && (
                    <DataTable
                      title="ForgTin Seite"
                      columns={columnsForgTin}
                      data={forgTinSeite}
                      pagination={true}
                      paginationComponentOptions={{
                        rowsPerPageText: "Zeilen anzeigen:",
                        rangeSeparatorText: "von",
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: "Alle",
                      }}
                      exportDisabled={role === "Retailer"}
                    />
                  )}

                  {schwindelLine.length !== 0 && (
                      <DataTable
                          title="Schwindel"
                          columns={columnsTextData}
                          data={schwindelLine}
                          pagination={true}
                          paginationComponentOptions={{
                            rowsPerPageText: "Zeilen anzeigen:",
                            rangeSeparatorText: "von",
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: "Alle",
                          }}
                          exportDisabled={role === "Retailer"}
                      />
                  )}
                  {hyperLine.length !== 0 && (
                      <DataTable
                          title="Beschwerden"
                          columns={columnsTextData}
                          data={hyperLine}
                          pagination={true}
                          paginationComponentOptions={{
                            rowsPerPageText: "Zeilen anzeigen:",
                            rangeSeparatorText: "von",
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: "Alle",
                          }}
                          exportDisabled={role === "Retailer"}
                      />
                  )}
                  {migraeneLine.length !== 0 && (
                      <DataTable
                          title="Migräne"
                          columns={columnsTextData}
                          data={migraeneLine}
                          pagination={true}
                          paginationComponentOptions={{
                            rowsPerPageText: "Zeilen anzeigen:",
                            rangeSeparatorText: "von",
                            noRowsPerPage: false,
                            selectAllRowsItem: false,
                            selectAllRowsItemText: "Alle",
                          }}
                          exportDisabled={role === "Retailer"}
                      />
                  )}

                  {bemerkungen.length !== 0 ? (
                    <DataTable
                      title="Notizen"
                      columns={columns}
                      data={bemerkungen}
                      pagination={true}
                      paginationComponentOptions={{
                        rowsPerPageText: "Zeilen anzeigen:",
                        rangeSeparatorText: "von",
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: "Alle",
                      }}
                      exportDisabled={role === "Retailer"}
                    />
                  ) : (
                    <h4 style={{ marginTop: 10 }}>Keine Notizen</h4>
                  )}
                </div>
              </div>
            ) : (
              <h4
                style={{
                  paddingBottom: "20px",
                  marginTop: "30px",
                  color: "rgba(100,100,100,1)",
                }}
              >
                Keine Daten
              </h4>
            )}
          </div>
        </div>
      )
    } else {
      return ""
    }
  }

  return (
    <div>
      <main>
        {(role === "Administrator" || role === "Retailer" || role === "Mitarbeiter") &&
            <div className="btnTop">
              <AddUserModal getAll={getAllUsers} clear={clearSelected} />
            </div>
        }

        <div className="mainContainer">
          <div className="leftContainer">

            {userDataLoading ? (
                <div
                    style={{width: "100%", textAlign: "center", padding: 20}}
                >
                  <CircularProgress />
                </div>
            ) :  <>
              {(!!role && role !== "Benutzer") &&
                  <>
                    <div className="filterBox">
                      <input type="hidden" value="something"/>

                      <input
                          type="text"
                          name="name-filter"
                          placeholder="Benutzer suchen"
                          className="form-control form-control-md"
                          onChange={(event) => {
                            setUserSearch(event.target.value);
                          }}
                          value={userSearch}
                          style={{ marginBottom: 5 }}
                          autoComplete="off"
                      />
                      <YearPicker
                          placeholder="Geburtsjahr"
                          thisYearLabel="Heutiges Datum"
                          onChange={(date) => setGbFilter(date)}
                      />

                      <MDBDropdown id={"newUserLand"} style={{ fontSize: "14px" }}>
                        <MDBDropdownToggle caret className="drops">
                          {landFilter}
                        </MDBDropdownToggle>
                        <MDBDropdownMenu basic>
                          {getCountryOptions(setLandFilter)}
                        </MDBDropdownMenu>
                      </MDBDropdown>
                      <MDBBtn
                          style={{ border: "1px solid rgba(0,0,0,1)" }}
                          onClick={() => setAufsteigend(!aufsteigend)}
                      >
                        A{" "}
                        {aufsteigend === false ? (
                            <AiOutlineArrowDown size={18} color="black" />
                        ) : (
                            <AiOutlineArrowUp size={18} color="black" />
                        )}
                      </MDBBtn>
                    </div>
                    <MDBBtn
                        onClick={deleteFilter}
                        style={{
                          backgroundColor: "rgba(220,220,220,0.8)",
                          marginLeft: 0,
                          marginBottom: 5,
                        }}
                    >
                      Filter löschen <MdClose size={24} color="black" />
                    </MDBBtn>
                    <div className="nameBoxes">
                      {anzeigeData !== undefined &&
                          anzeigeData.map((data, index) => (
                              <BenutzerAnzeigeBtn
                                  data={data.data}
                                  id={data.id}
                                  wholeData={data}
                                  click={setData}
                                  index={index}
                              />
                          ))}
                      <Row
                          style={{
                            width: "100%",
                            marginBottom: 5,
                            paddingLeft: 5,
                            paddingRight: 5,
                            marginLeft: 0,
                            marginRight: 0,
                            marginTop: 5,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                      >
                        <Col>
                          <MDBBtn
                              style={{ border: "1px solid rgba(0,0,0,1)" }}
                              onClick={() => setPage(1)}
                              disabled={page === 1 ? true : false}
                          >
                            <FiChevronsLeft size={20} color="black" />
                          </MDBBtn>
                        </Col>
                        <Col>
                          <MDBBtn
                              style={{ border: "1px solid rgba(0,0,0,1)" }}
                              onClick={() => setPage(page - 1)}
                              disabled={page === 1 ? true : false}
                          >
                            <FiChevronLeft size={20} color="black" />
                          </MDBBtn>
                        </Col>
                        <Col>
                          <MDBBtn style={{ border: "1px solid rgba(0,0,0,1)" }}>
                            {page}
                          </MDBBtn>
                        </Col>
                        <Col>
                          <MDBBtn
                              style={{ border: "1px solid rgba(0,0,0,1)" }}
                              onClick={() => setPage(page + 1)}
                              disabled={page === availablePages}
                          >
                            <FiChevronRight size={20} color="black" />
                          </MDBBtn>
                        </Col>
                        <Col>
                          <MDBBtn
                              style={{ border: "1px solid rgba(0,0,0,1)" }}
                              disabled={page === availablePages}
                              onClick={() =>
                                  setPage(Math.ceil(filterEveryUser.length / 8))
                              }
                          >
                            <FiChevronsRight size={20} color="black" />
                          </MDBBtn>
                        </Col>
                      </Row>
                    </div>
                    <p className="normalp seite">
                      Seite {page} von{" "}
                      {Math.ceil(filterEveryUser.length / 8) === 0
                          ? 1
                          : Math.ceil(filterEveryUser.length / 8)}
                    </p>
                    <p className="normalp benutzer">
                      {filterEveryUser.length} Benutzer
                    </p>
                  </>
              }
            </>}

          </div>
          <div className="rightContainer">
            {userData !== undefined && handleUserData()}
          </div>
        </div>
      </main>
      {showFlash && (
        <FooterBox
          type={flashType}
          message={flashMessage}
          onExit={() => setShowFlash(false)}
        />
      )}
    </div>
  )
}
