import React, {useEffect, useState} from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Homescreen from "./components/Home/Homescreen";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import NavbarTop from "./components/Navbar/NavbarTop";
import Benutzerscreen from "./components/Benutzer/Benutzerscreen";
import EinstellungsScreen from "./components/Einstellungen/EinstellungsScreen";
import TipsScreen from "./components/Tips/TipsScreen";
import BenarchtigScreen from "./components/Benachrichtigungen/BenarchtigScreen";
import { firebase } from "./components/Firebase";
import Auth from "./components/Auth/Auth";
import AbmeldeScreen from "./components/Abmelden/AbmeldeScreen";
import FooterBox from "./components/FooterBox";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoginCodesScreen from "./components/LoginCodes/LoginCodesScreen";
import {getLoggedInUser, isNavBarItemVisibleForRole} from "./utils/globals";

function App() {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [role, setRole] = useState("Benutzer");

  useEffect(() => {
    setLoading(true);
    checkUser();
  }, []);

  const checkUser = () => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setLoading(false);

        getLoggedInUser().then(res => {
          setRole(!!res ? res.rolle : null);
          setUser(res);
        });

      } else {
        setUser(false);
        setLoading(false);
        setRole(null);
      }
    });
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  // if (user === true) {

  const getRedirectAfterLogin = () => {
    if(role !== "Administrator"){
      return "/benutzer"
    }
    return "/";
  }

  return (
    <BrowserRouter>
      {user && <NavbarTop />}

      <Switch>
        <Route
          exact
          path="/login"
          render={() => {
            return (user ? <Redirect to={getRedirectAfterLogin()} /> : <Auth />)
          }}
        />

        <Route
            exact
            path="/"
            render={() => (user ? <Homescreen /> : <Redirect to="/login" />)}
        />

        {
            isNavBarItemVisibleForRole("benutzer", role) &&
            <Route
                exact
                path="/benutzer"
                render={() => (user ? <Benutzerscreen /> : <Redirect to="/login" />)}
            />
        }

        {
            isNavBarItemVisibleForRole("einstellungen", role) &&
            <Route
                exact
                path="/einstellungen"
                render={() =>
                    user ? <EinstellungsScreen /> : <Redirect to="/login" />
                }
            />
        }

        {
            isNavBarItemVisibleForRole("tipps", role) &&
            <Route
                exact
                path="/tipps"
                render={() => (user ? <TipsScreen /> : <Redirect to="/login" />)}
            />
        }

        {
            isNavBarItemVisibleForRole("benachrichtigungen", role) &&
            <Route
                exact
                path="/benachrichtigungen"
                render={() =>
                    user ? <BenarchtigScreen /> : <Redirect to="/login" />
                }
            />
        }

        {
            isNavBarItemVisibleForRole("logincodes", role) &&
            <Route
                exact
                path="/logincodes"
                render={() =>
                    user ? <LoginCodesScreen /> : <Redirect to="/login" />
                }
            />
        }


        <Route
          exact
          path="/abmelden"
          render={() => (user ? <AbmeldeScreen /> : <Redirect to="/login" />)}
        />
        {/* <Route exact path="/benachrichtigungen" component={Homescreen} />
          <Route exact path="/tips" component={Homescreen} />
          <Route exact path="/einstellungen" component={Homescreen} />
          <Route exact path="/abmelden" component={Homescreen} /> */}
      </Switch>
      <div style={{padding: 20, borderTop: "1px solid rgba(0,0,0,0.3)", width: "70%", marginLeft: "auto", marginRight: "auto", marginTop: 20, opacity: 0.75}}>
        <div style={{}}>
          <div style={{}}>
            ForgTin® ist eine geschützte Marke der Pansatori GmbH
          </div>
          <div style={{marginTop: 5}}>
            <div style={{fontWeight: "bold"}}>Impressum</div>
            <div>Pansatori GmbH</div>
            <div>Handelsrechtlicher Geschäftsführer: Mag. Klaus Grübl</div>
            <div>Laabstraße 96</div>
            <div>5280 Braunau am Inn</div>
            <div>Austria</div>
            <div>Tel. +43 (0)7722-22900 | E-Mail: office@pansatori.com</div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
  // } else {
  //   return <Auth />;
  // }
}

export default App;
