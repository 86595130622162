import React, { useEffect, useState } from "react"
import Alert from "@material-ui/lab/Alert"
import Fade from "@material-ui/core/Fade"

const styles = {
  alert: {
    left: "0",
    pointerEvents: "none",
    position: "fixed",
    bottom: 0,
    width: "100%",
    zIndex: "1500",
  },
  form: {
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0 10px 15px 0 #ccc",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "400px",
    height: "300px",
    margin: "0 auto",
    padding: "50px 0",
  },
  input: {
    width: "200px",
  },
  main: {
    backgroundColor: "#eef",
    height: "100vh",
    padding: "80px 20px",
  },
  text: {
    fontSize: "1.2rem",
    fontWeight: "700",
    marginBottom: "20px",
  },
}

const WithFlash = (props) => {
  const [flash, setFlash] = useState({
    severity: "",
    message: "",
  })
  const [showFlash, setShowFlash] = useState(null)

  const check = () => {
    const flash = {}

    flash.message = props.message
    flash.severity = props.type

    return flash
  }

  useEffect(() => {
    handleSubmit()
  }, [])

  const handleSubmit = () => {
    setShowFlash(true)

    setFlash(check())

    setTimeout(() => {
      setShowFlash(false)
      props.onExit()
    }, 4000)
  }

  return (
    <div>
      <Fade in={showFlash} timeout={{ enter: 300, exit: 1000 }}>
        <Alert style={styles.alert} severity={flash.severity}>
          {flash.message}
        </Alert>
      </Fade>
    </div>
  )
}

export default WithFlash
