import React, { Component } from "react"
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact"
import { db, firebase } from "../Firebase"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import FooterBox from "../FooterBox"
import FlashMessage from "react-flash-message"
import { confirmAlert } from "react-confirm-alert" // Import

import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact"
import { FaPen, FaWizardsOfTheCoast } from "react-icons/fa"
import Rating from "@material-ui/lab/Rating"

class EditTipModal extends Component {
  state = {
    modal16: false,
    tipName: this.props.name,
    anzeige: this.props.anzeige,
    tipTitle: this.props.title,
    tipTitleEn: this.props.titleEn,
    tipMessageEn: this.props.messagEn,
    flashType: "",
    flashMessage: "",
    showFlash: false,
  }

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    })
  }

  toggleFooter = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  handleChange = (e) => {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  handleCheck = () => {
    this.setState({ study: !this.state.study })
  }

  deleteTipp = () => {
    confirmAlert({
      title: "Tipp löschen",
      message: "Sind Sie sicher, dass Sie diesen Tipp löschen möchten?",
      buttons: [
        {
          label: "Tipp löschen ",
          onClick: () => {
            this.props.toggleLoading()
            let tempData = []
            db.collection("Admin")
              .doc("admin")
              .get()
              .then((doc) => {
                tempData = doc.data()["Tipps"]

                tempData.splice(this.props.index, 1)

                db.collection("Admin")
                  .doc("admin")
                  .update({
                    Tipps: tempData,
                  })
                  .then(() => {
                    this.setState({
                      modal16: false,
                    })
                    this.props.toggleLoading()
                    this.setState({
                      flashMessage: "Tipp wurde erfolgreich gelöscht!",
                      flashType: "success",
                      showFlash: true,
                    })
                  })
                  .catch((error) => {
                    this.props.toggleLoading()
                    alert(error.message)
                  })
              })
          },
        },
        {
          label: "Abbrechen",
          onClick: () => {},
        },
      ],
    })
  }

  handleSave = () => {
    const { anzeige, tipName } = this.state
    if (anzeige && tipName) {
      let tempData = []
      db.collection("Admin")
        .doc("admin")
        .get()
        .then((doc) => {
          tempData = doc.data()["Tipps"]

          tempData[this.props.index] = {
            anzeige: this.state.anzeige,
            bewertung: this.props.bewertung,
            message: this.state.tipName,
            id: this.props.id,
            anlage: this.props.anlage,
            title: this.state.tipTitle,
            bewertungen: this.props.bewertungen,
            titleEn: this.state.tipTitleEn,
            messageEn: this.state.tipMessageEn,
          }

          db.collection("Admin")
            .doc("admin")
            .update({
              Tipps: tempData,
            })
            .then(() => {
              this.setState({
                modal16: false,
              })

              this.setState({
                flashMessage: "Änderungen erfolgreich gespeichert!",
                flashType: "success",
                showFlash: true,
              })
            })
        })
    } else {
      this.setState({
        flashMessage: "Bitte alle Felder ausfüllen!",
        flashType: "error",
        showFlash: true,
      })
    }
  }

  render() {
    return (
      <div>
        <MDBBtn color="dark" onClick={this.toggle(16)} className="outerBtn">
          <FaPen size={17} />
        </MDBBtn>
        <MDBModal isOpen={this.state.modal16} toggle={this.toggle(16)} centered>
          <MDBModalHeader toggle={this.toggle(16)}>
            Tipp bearbeiten
          </MDBModalHeader>
          <MDBModalBody>
            <Row>
              <Col>
                <label htmlFor="tipTitle">Tipp-Titel (Deutsch)</label>
                <input
                  type="text"
                  id="tipTitle"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.tipTitle}
                  style={{ marginBottom: "15px" }}
                  name="tipTitle"
                />
                <label htmlFor="tipTitle">Tipp-Titel (Englisch)</label>
                <input
                  type="text"
                  id="tipTitleEn"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.tipTitleEn}
                  style={{ marginBottom: "15px" }}
                  name="tipTitleEn"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="tipName">Tipp-Text (Deutsch)</label>
                <textarea
                  rows={5}
                  type="text"
                  id="tipName"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.tipName}
                  style={{ marginBottom: "16px" }}
                  name="tipName"
                />
                <label htmlFor="tipName">Tipp-Text (Englisch)</label>
                <textarea
                  rows={5}
                  type="text"
                  id="tipName"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.tipMessageEn}
                  style={{ marginBottom: "16px" }}
                  name="tipMessageEn"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="anzeige">Anzeigeort</label>
                <MDBDropdown id="anzeige" style={{ fontSize: "14px" }}>
                  <MDBDropdownToggle caret className="drops">
                    {this.state.anzeige}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu basic>
                    <MDBDropdownItem
                      onClick={() =>
                        this.setState({ anzeige: "Header Homescreen" })
                      }
                    >
                      Header Homescreen
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      onClick={() =>
                        this.setState({ anzeige: "Footer Homescreen" })
                      }
                    >
                      Footer Homescreen
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </Col>
            </Row>
            <Row>
              <Col>
                <Rating
                  name="read-only"
                  value={this.props.bewertung}
                  readOnly
                  precision={0.1}
                />
              </Col>
              <Col />
            </Row>
            <Row>
              <Col>
                <MDBBtn color="danger" onClick={this.deleteTipp}>
                  Tipp löschen
                </MDBBtn>
              </Col>
              <Col />
            </Row>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggle(16)}>
              Schließen
            </MDBBtn>
            <MDBBtn color="dark" onClick={this.handleSave}>
              Änderungen speichern
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        {this.state.showFlash && (
          <FooterBox
            type={this.state.lashType}
            message={this.state.flashMessage}
            onExit={() => this.setState({ showFlash: false })}
          />
        )}
      </div>
    )
  }
}

export default EditTipModal
