import React, {useEffect, useState} from "react";
import "./index.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import ForgTinLogo from "../ForgTinLogo";
import { NavLink } from "react-router-dom";
import {getLoggedInUser, isNavBarItemVisibleForRole} from "../../utils/globals";

export default function NavbarTop() {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState("Benutzer");

  useEffect(() => {
    getLoggedInUser().then(res => {
      setUser(res);
      setRole(res.rolle);
    })
  }, []);

  return (
    <div className="navContainer">
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <NavLink to="/">
          <ForgTinLogo width={160} />
        </NavLink>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" style={{ alignItems: "center" }}>
            {
              isNavBarItemVisibleForRole("dashboard", role) &&
                <NavLink
                    exact={true}
                    className="navLinks"
                    activeClassName="activeLink"
                    to="/"
                >
                  Dashboard
                </NavLink>
            }
            {
                isNavBarItemVisibleForRole("benutzer",role) &&
                <NavLink
                    exact={true}
                    className="navLinks"
                    activeClassName="activeLink"
                    to="/benutzer"
                >
                  Benutzer
                </NavLink>
            }
            {
                isNavBarItemVisibleForRole("benachrichtigungen", role) &&
                <NavLink
                    exact={true}
                    className="navLinks"
                    activeClassName="activeLink"
                    to="/benachrichtigungen"
                >
                  Benachrichtigung
                </NavLink>
            }
            {
                isNavBarItemVisibleForRole("tipps", role) &&
                <NavLink
                    exact={true}
                    className="navLinks"
                    activeClassName="activeLink"
                    to="/tipps"
                >
                  Tipps
                </NavLink>
            }
            {
                isNavBarItemVisibleForRole("einstellungen", role) &&
                <NavLink
                    exact={true}
                    className="navLinks"
                    activeClassName="activeLink"
                    to="/einstellungen"
                >
                  Einstellungen
                </NavLink>
            }
            {
                isNavBarItemVisibleForRole("logincodes", role) &&
                <NavLink
                    exact={true}
                    className="navLinks"
                    activeClassName="activeLink"
                    to="/logincodes"
                >
                  Login-Codes
                </NavLink>
            }

            <NavLink
              exact={true}
              className="navLinks"
              activeClassName="activeLink"
              to="/abmelden"
            >
              Abmelden
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div style={{width: "70%", marginLeft: "auto", marginRight: "auto"}}>
        <div style={{}}><small>Angemeldet als {!!user ? user.email : ''}</small></div>
        <div><small>{!!user ? user.vorname : ''} {!!user ? user.nachname : ''}</small></div>
        <div><small>Rolle: {!!user && !!user.rolle ? user.rolle : 'Benutzer'}</small></div>
        {(!!user && user.adresse) && <div>
          <div><small>{user.adresse}</small></div>
          <div><small>{user.plz} {user.ort}</small></div>
        </div>
        }

      </div>
    </div>
  );
}
