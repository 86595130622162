import React, { useEffect } from "react";
import { MDBBtn } from "mdbreact";
import { FaRegCalendarAlt } from "react-icons/fa";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates";
import * as moment from "moment";
import deLocale from "moment/locale/de-at";

export default function ZeitraumButton(props) {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [focusedInput, setFocuseInput] = React.useState(startDate);

  const setMonths = (startDateProps, endDateProps) => {
    setStartDate(startDateProps);
    setEndDate(endDateProps);
    props.setDates(startDateProps.toDate(), endDateProps.toDate()); // noch toDate weils moment object ist
  };

  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, []);

  return (
    <DateRangePicker
      minimumNights={0}
      startDatePlaceholderText="Von"
      endDatePlaceholderText="Bis"
      startDate={startDate} // momentPropTypes.momentObj or null,
      startDateId={props.startId} // PropTypes.string.isRequired,
      endDate={endDate} // momentPropTypes.momentObj or null,
      endDateId={props.endId} // PropTypes.string.isRequired,
      onDatesChange={({ startDate, endDate }) => setMonths(startDate, endDate)} // PropTypes.func.isRequired,
      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={(focusedInput) => setFocuseInput(focusedInput)} // PropTypes.func.isRequired,
      isOutsideRange={() => {
        return false;
      }}
    />
    // <DatePicker
    //   selected={new Date()}
    //   closeOnScroll
    //   customInput={
    //     <MDBBtn color="white" className="zeitBtn">
    //       <FaRegCalendarAlt size={24} className="zeitIcon" />
    //       19.01.2020 --> 19.02.2020
    //     </MDBBtn>
    //   }
    // />
  );
}
