import React, {useEffect, useState} from "react"
import "./index.css"
import BenutzerButton from "./BenutzerButton"
import ZeitraumButton from "./ZeitraumButton"
import StatistikBox from "./StatistikBox"
import * as moment from "moment"
import deLocale from "moment/locale/de-at"
import { db, firebase } from "../Firebase"
import { isAfter, isSameDay, isBefore } from "date-fns"
import jstat, { map } from "jstat"
import format from "date-fns/format"
import { createObjectCsvWriter } from "csv-writer"
import {getLoggedInUser} from "../../utils/globals";

let before = new Date()
before.setMonth(before.getMonth() - 1)

export default function Homescreen(props) {
  const [startDate, setStartDate] = React.useState(moment(before))
  const [endDate, setEndDate] = React.useState(moment(new Date()))
  const [durchschnittData, setDurchschnittData] = React.useState([])
  const [durchschnittBel, setDurchschnittBel] = React.useState([])
  const [belLine, setBelLine] = React.useState()
  const [lautLine, setLautLine] = React.useState()
  const [everyUser, setEveryUser] = React.useState([])
  const [role, setRole] = useState("Benutzer");


  const setDates = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  useEffect(() => {
    getLoggedInUser().then(res => {
      setRole(res.rolle);
    })
  }, []);

  useEffect(() => {
    getAllData()
  }, [startDate, endDate])

  useEffect(() => {
    getAllData()
  }, [])
  function pivot(arr) {
    var mp = new Map()

    function setValue(a, path, val) {
      if (Object(val) !== val) {
        // primitive value
        var pathStr = path.join(".")
        var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr)
        a[i] = val
      } else {
        for (var key in val) {
          setValue(a, key == "0" ? path : path.concat(key), val[key])
        }
      }

      return a
    }

    var result = arr.map((obj) => setValue([], [], obj))
    return [[...mp.keys()], ...result]
  }

  function toCsv(arr) {
    const data = arr
      .map((row) =>
        row
          .map((val, index) => {
            return isNaN(val) ? JSON.stringify(val) : +val
          })
          .join(",")
      )
      .join("\n")

    return data
  }

  const download = () => {
    const header = [
      "Vorname",
      "Nachname",
      "Email",
      "Erinnerungen",
      "Geburtsdatum",
      "Land",
      "Ort",
      "PLZ",
      "Login Code",
      "Studienteilnehmer",
      "Wann Chronisch?",
      "Lautstärke",
      "Belastung",
      "Datum",
      "ForgTin",
      "Kiefer Verspannung",
      "Nacken Verspannung",
      "Stress",
      "Stimmungslage",
      "Bemerkung",
    ]
    const data = everyUser.map((user) => {
      return [
        user.data.vorname,
        user.data.nachname,
        user.data.email,
        user.data.erinnerungen === true ? "An" : "Aus",
        user.data.geburtsdatum
          ? format(user.data.geburtsdatum.toDate(), "d.M.yyyy")
          : "Nicht angegeben",
        user.data.land,
        user.data.ort ? user.data.ort : "Nicht angegeben",
        user.data.plz ? user.data.plz : "Nicht angegeben",
        user.data.logCode ? user.data.logCode : "",
        user.data.studienteilnehmer === true ? "Ja" : "Nein",
        user.data.wannChronisch ? user.data.wannChronisch : "Nicht angegeben",
        user.eintraege ? user.eintraege.map((eintrag) => eintrag) : [],
      ]
    })

    // var csvContent = toCsv(pivot(data));
    var csvContent = () => {
      var gesamt = ""
      gesamt += header.join(",")
      gesamt += "\n"

      data
        .map((item, index) => {
          item.map((innerItem, idex) => {
            var gesamtLine

            if (Array.isArray(innerItem)) {
              if (innerItem.length === 0) {
                var userLine = item.map((i, index) => {
                  if (index < 10 && i !== undefined) {
                    return i
                  }
                })

                return (gesamt += userLine + "\n")
              } else {
                var userLine = item.map((i, index) => {
                  if (index < 10 && i !== undefined) {
                    if (i.indexOf(",") === 1) {
                      var newI = i.replace(",", ".")
                      return newI
                    }
                    return i
                  }
                })

                return innerItem.map((mapItem, index) => {
                  var forgTin = mapItem.forgTinBeide
                    ? "Beide Seiten"
                    : mapItem.forgTinLinks
                    ? "Links"
                    : mapItem.forgTinRechts
                    ? "Rechts"
                    : "Keine Seite"
                  var bemerkung = !mapItem.bemerkung
                    ? "Keine"
                    : mapItem.bemerkung
                  return (gesamt +=
                    (userLine ? userLine : "") +
                    mapItem.lautstaerke +
                    "," +
                    mapItem.belastung +
                    "," +
                    format(mapItem.eingetragen.toDate(), "d.M.yyyy HH:mm") +
                    "," +
                    forgTin +
                    "," +
                    mapItem.kieferVerspannung +
                    "," +
                    mapItem.nackenVerspannung +
                    "," +
                    mapItem.stress +
                    "," +
                    mapItem.stimmungslage +
                    "," +
                    encodeURIComponent(bemerkung.replace(",", " "))
                      .replace(/%20/g, " ")
                      .replace(/%C3%A4/g, "ä")
                      .replace(/%E2%80%9E/g, "„")
                      .replace(/%E2%80%9C/g, "“")
                      .replace(/%C3%BC/g, "ü")
                      .replace(/%3B/g, "")
                      .replace(/%C3%9F/g, "ß")
                      .replace(/%C3%B6/g, "ö")
                      .replace(/%3A/g, ":") +
                    "\n")
                })
              }
            } else {
              gesamtLine += innerItem + ","
              // return (gesamt += innerItem + ",");
            }
          })
          // return (gesamt += item.join(","));
        })
        .join("\n")

      return gesamt
    }

    var aTag = document.getElementById("getAllUserBtn")

    aTag.href =
      "data:Application/octet-stream," + encodeURIComponent(csvContent())
    aTag.target = "_blank"
    const test = new Date()

    aTag.download =
      format(test, "d.M.yyyy ") +
      test.getHours() +
      ":" +
      test.getMinutes() +
      "_" +
      "Alle_Benutzer" +
      ".csv"

    // everyUser.forEach((eintrag) => {
    //   // var json = eintrag.items;
    //   // var fields = Object.keys(json[0]);
    //   // var replacer = function (key, value) {
    //   //   return value === null ? "" : value;
    //   // };
    //   // var csv = json.map(function (row) {
    //   //   return fields
    //   //     .map(function (fieldName) {
    //   //       return JSON.stringify(row[fieldName], replacer);
    //   //     })
    //   //     .join(",");
    //   // });
    //   // csv.unshift(fields.join(",")); // add header column
    //   // csv = csv.join("\r\n");
    //   console.log(eintrag);
    // });
    // alert(
    //   "Arbeite gerade an diesem Feature, wird die nächste Zeit funktionieren :)"
    // );
    // let csvContent;

    // csvContent =
    //   "data:text/csv;charset=utf-8," +
    //   everyUser
    //     .map((e) => {
    //       console.log(e);
    //       if (e.length > 0) {
    //         e.forEach((eintrag) => {
    //           eintrag.join(",");
    //         });
    //       } else {
    //         e.join(",");
    //       }
    //     })
    //     .join("\n");

    // var encodedUri = encodeURI(csvContent);
    // window.open(encodedUri);
  }

  //arrWeight == y also value arrHeight == x also time
  function regression(arrWeight, arrHeight) {
    let r, sy, sx, b, a, meanX, meanY
    r = jstat.corrcoeff(arrHeight, arrWeight)
    sy = jstat.stdev(arrWeight)
    sx = jstat.stdev(arrHeight)
    meanY = jstat(arrWeight).mean()
    meanX = jstat(arrHeight).mean()
    b = r * (sy / sx)
    a = meanY - meanX * b
    //Set up a line
    let y1, y2, x1, x2
    x1 = jstat.min(arrHeight)
    x2 = jstat.max(arrHeight)
    y1 = a + b * x1
    y2 = a + b * x2
    return {
      line: [
        [x1, y1],
        [x2, y2],
      ],
      r,
    }
  }

  const getAllData = () => {
    let allData = []
    let maxl = []
    let allDataSets = []
    let allSorted = []
    const everyone = []
    if (firebase.auth().currentUser) {
      db.collection("Benutzer")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            everyone.push(doc.data())

            // doc.data() is never undefined for query doc snapshots
            let eintraege = doc.data()["eintraege"]
            if (eintraege !== undefined) {
              // let eintraege = doc.data()["eintraege"];

              if (eintraege.length > 0 && !isNaN(eintraege[0].lautstaerke)) {
                let filtered
                try {
                  filtered = eintraege.filter(
                    (eintrag) =>
                      (isAfter(
                        eintrag.eingetragen.toDate(),
                        startDate.toDate()
                      ) &&
                        isBefore(
                          eintrag.eingetragen.toDate(),
                          endDate.toDate()
                        )) ||
                      isSameDay(
                        eintrag.eingetragen.toDate(),
                        startDate.toDate()
                      ) ||
                      isSameDay(eintrag.eingetragen.toDate(), endDate.toDate())
                  )
                } catch (e) {
                  filtered = eintraege.filter(
                    (eintrag) =>
                      (isAfter(eintrag.eingetragen.toDate(), startDate) &&
                        isBefore(eintrag.eingetragen.toDate(), endDate)) ||
                      isSameDay(eintrag.eingetragen.toDate(), startDate) ||
                      isSameDay(eintrag.eingetragen.toDate(), endDate)
                  )
                }

                allData.push(eintraege)

                filtered.map((eintrag) => allDataSets.push(eintrag))

                // if (filtered.length > maxl.length) {
                //   maxl = filtered;
                // }
                // if (
                //   filtered.length > 0 &&
                //   isBefore(filtered[0].eingetragen.toDate(), minDate)
                // ) {
                //   minDate = filtered[0].eingetragen.toDate();
                // }
                // if (
                //   filtered.length > 0 &&
                //   isAfter(
                //     filtered[filtered.length - 1].eingetragen.toDate(),
                //     maxDate
                //   )
                // ) {
                //   maxDate = filtered[filtered.length - 1].eingetragen.toDate();
                // }
              }
            }
          })
          //sort function
          function sortFunction(a, b) {
            if (
              a.eingetragen.toDate().getTime() ===
              b.eingetragen.toDate().getTime()
            ) {
              return 0
            } else {
              return a.eingetragen.toDate().getTime() <
                b.eingetragen.toDate().getTime()
                ? -1
                : 1
            }
          }
          //sort all arrays together

          allSorted = allDataSets.sort(sortFunction)

          if (allSorted.length > 0) {
            //filter not working but ranges work better
            //von hier
            // if (
            //   !isSameDay(maxl[0].eingetragen.toDate(), minDate) &&
            //   isBefore(minDate, maxl[0].eingetragen.toDate())
            // ) {
            //   console.log("drinn");
            //   maxl.unshift({
            //     eingetragen: firebase.firestore.Timestamp.fromDate(minDate),
            //     lautstaerke: maxl[0].lautstaerke,
            //     belastung: maxl[0].belastung,
            //   });
            // }
            // if (
            //   !isSameDay(maxl[maxl.length - 1].eingetragen.toDate(), maxDate) &&
            //   isAfter(maxDate, maxl[maxl.length - 1].eingetragen.toDate())
            // ) {
            //   maxl.push({
            //     eingetragen: firebase.firestore.Timestamp.fromDate(maxDate),
            //     lautstaerke: maxl[maxl.length - 1].lautstaerke,
            //     belastung: maxl[maxl.length - 1].belastung,
            //   });
            // }
            //bis hiier

            //calculate average of lautsärke
            //useless map function
            // allSorted.map((eintrag) => {
            //   //eig maxl.
            //   durchschnitt.push(eintrag);
            // });

            const tempDurchLaut = allSorted
            const tempDurchBel = allSorted

            const neuerDurchLaut = tempDurchLaut.map((eintrag, index) => {
              return [
                (tempDurchLaut[index].eingetragen =
                  eintrag.eingetragen.toDate().getTime() + index * 1000),
                (tempDurchLaut[index].lautstaerke = eintrag.lautstaerke),
                // / tempDurchLautAll.length)
              ]
            })
            //regression used
            const tempLautX = []
            const tempLautY = []
            neuerDurchLaut.forEach((eintrag) => {
              tempLautX.push(eintrag[0])
              tempLautY.push(eintrag[1])
            })

            setLautLine(regression(tempLautY, tempLautX))

            setDurchschnittData(neuerDurchLaut)

            const tempBelX = []
            const tempBELY = []

            const neuerDurchBel = tempDurchBel.map((eintrag, index) => {
              return [
                (tempDurchBel[index].eingetragen = eintrag.eingetragen),
                (tempDurchBel[index].belastung = eintrag.belastung),
              ]
            })
            neuerDurchBel.forEach((eintrag) => {
              tempBelX.push(eintrag[0])
              tempBELY.push(eintrag[1])
            })

            setDurchschnittBel(neuerDurchBel)
            setBelLine(regression(tempBELY, tempBelX))
          }
          setEveryUser(everyone)
        })
    }
  }

  return (
    <div>
      <main>
        <div className="topHomeContainer">
          {role == "Administrator" &&
              <BenutzerButton onClick={download} />
          }
        </div>
        {role == "Administrator" &&
            <div className="zeitContainer">
              <h5>
                Statistiken <br />
              </h5>
              <p>Zeitraum wählen:</p>

              <ZeitraumButton
                  startId={"neewonea2"}
                  endId={"neia1a"}
                  setDates={setDates}
                  startDate={startDate}
                  endDate={endDate}
              />
            </div>
        }
        {role == "Administrator" &&
            <div className="mainContainer">
              {durchschnittData && lautLine && (
                  <StatistikBox
                      index={1}
                      zusatz="Lautstärke"
                      data={durchschnittData}
                      data2={lautLine.line}
                      show={false}
                      name="Durchschnitt Lautstärke"
                      linear={true}
                      user="Alle"
                  />
              )}
              {belLine && durchschnittBel && (
                  <StatistikBox
                      index={2}
                      zusatz="Belastung"
                      data={durchschnittBel}
                      data2={belLine.line}
                      show={false}
                      name="Durchschnitt Belastung"
                      linear={true}
                      user="Alle"
                  />
              )}
              {/* <StatistikBox index={2} />
          <StatistikBox index={3} /> */}
            </div>
        }
      </main>
    </div>
  )
}
