import React, { useEffect } from "react"
import "./index.css"
import { db, firebase } from "../Firebase"
import { MdClose } from "react-icons/md"
import isSameDay from "date-fns/isSameDay"
import TippsRow from "./TippsRow"
import { BsFilter } from "react-icons/bs"
import AddTippModal from "./AddTippModal"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import FooterBox from "../FooterBox"
import CircularProgress from "@material-ui/core/CircularProgress"
import format from "date-fns/format"
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn,
} from "mdbreact"

export default function TipsScreen() {
  const [search, setSearch] = React.useState("")
  const [tipps, setTipps] = React.useState([])
  const [filteredTipps, setFilteredTipps] = React.useState([])
  const [againFiltered, setAgainFiltered] = React.useState([])
  const [anzeigeFilter, setAnzeigeFilter] = React.useState("Alle")
  const [anlageDatum, setAnlageDatum] = React.useState(null)
  const [filter, setFilter] = React.useState(false)
  const [bewertungsFilter, setBewertungsFilter] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [selectedTip, setSelectedTip] = React.useState(null)

  const resetFilter = () => {
    setSearch("")
    setAnzeigeFilter("Alle")
    setBewertungsFilter("")
    setAnlageDatum(null)
    setFilteredTipps(tipps)
  }
  const toggleTipp = () => {
    setLoading(!loading)
  }

  const getData = () => {
    db.collection("Admin")
      .doc("admin")
      .onSnapshot((doc) => {
        setTipps(doc.data()["Tipps"])
        setFilteredTipps(doc.data()["Tipps"])
        setSelectedTip(doc.data()["selectedTip"])
      })
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    let filteredData = tipps
    let newData

    if (bewertungsFilter === "Bewertung hoch") {
      newData = filteredData.sort(
        (a, b) => parseFloat(b.bewertung) - parseFloat(a.bewertung)
      )

      setFilteredTipps(newData)
    } else if (bewertungsFilter === "Bewertung niedrig") {
      newData = filteredData.sort(
        (a, b) => parseFloat(a.bewertung) - parseFloat(b.bewertung)
      )
    } else {
      newData = filteredData
    }

    if (search !== "" && anlageDatum !== null && anzeigeFilter !== "Alle") {
      filteredData = newData.filter((item) => {
        return (
          item.title.toLowerCase().includes(search.toLowerCase()) &&
          item.anzeige === anzeigeFilter &&
          isSameDay(item.anlage.toDate(), anlageDatum)
        )
      })
    } else if (
      search !== "" &&
      anlageDatum !== null &&
      anzeigeFilter === "Alle"
    ) {
      filteredData = newData.filter((item) => {
        return (
          item.title.toLowerCase().includes(search.toLowerCase()) &&
          isSameDay(item.anlage.toDate(), anlageDatum)
        )
      })
    } else if (
      search !== "" &&
      anzeigeFilter !== "Alle" &&
      anlageDatum === null
    ) {
      filteredData = newData.filter((item) => {
        return (
          item.title.toLowerCase().includes(search.toLowerCase()) &&
          item.anzeige === anzeigeFilter
        )
      })
    } else if (
      search !== "" &&
      anlageDatum === null &&
      anzeigeFilter === "Alle"
    ) {
      filteredData = newData.filter((item) => {
        return item.title.toLowerCase().includes(search.toLowerCase())
      })
    } else if (
      search === "" &&
      anlageDatum !== null &&
      anzeigeFilter !== "Alle"
    ) {
      filteredData = newData.filter((item) => {
        return (
          item.anzeige === anzeigeFilter &&
          isSameDay(item.anlage.toDate(), anlageDatum)
        )
      })
    } else if (
      search === "" &&
      anlageDatum !== null &&
      anzeigeFilter === "Alle"
    ) {
      filteredData = newData.filter((item) => {
        return isSameDay(item.anlage.toDate(), anlageDatum)
      })
    } else if (
      search === "" &&
      anlageDatum === null &&
      anzeigeFilter !== "Alle"
    ) {
      filteredData = newData.filter((item) => {
        return item.anzeige === anzeigeFilter
      })
    } else {
      filteredData = newData
    }

    setFilteredTipps([...filteredData])
  }, [search, anlageDatum, anzeigeFilter, bewertungsFilter])

  useEffect(() => {
    if (search === "" && !anlageDatum && anzeigeFilter === "Alle") {
      setFilter(false)
    } else {
      setFilter(true)
    }
  }, [filteredTipps])

  return (
    <div>
      {loading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255,255,255,0.7)",
            zIndex: 9999,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <CircularProgress />
        </div>
      )}
      <main>
        <div className="topContainer">
          <p>Tipp erstellen</p>
        </div>
        <div className="midContainer">
          <div className="underMid">
            <input
              className="form-control "
              type="text"
              placeholder="Tipps durchsuchen"
              aria-label="Search"
              id="searchfield"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
            {/* <button className="iconBtn">
              <IoIosSearch size={25} className="searchIcon" />
            </button> */}
          </div>

          <AddTippModal toggleLoading={() => setLoading(!loading)} />
        </div>
        <div className="mainContainer">
          {filter && (
            <div
              style={{
                marginBottom: 10,
              }}
            >
              <div style={{ paddingRight: 30 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h5 style={{ margin: 0 }}>Filter: </h5>
                </div>
                {anlageDatum !== null ? (
                  <p style={{ margin: 0 }}>
                    Datum: <strong>{format(anlageDatum, "dd.MM.yyyy")}</strong>
                  </p>
                ) : (
                  ""
                )}
                {search !== "" ? (
                  <p style={{ margin: 0 }}>
                    Suche: <strong>{search}</strong>
                  </p>
                ) : (
                  ""
                )}
                {anzeigeFilter !== "Alle" ? (
                  <p style={{ margin: 0 }}>
                    Anzeige: <strong>{anzeigeFilter}</strong>
                  </p>
                ) : (
                  ""
                )}
              </div>
              <MDBBtn
                onClick={resetFilter}
                style={{
                  backgroundColor: "rgba(220,220,220,0.8)",
                  marginLeft: 0,
                  marginTop: 15,
                }}
              >
                Filter löschen <MdClose size={24} color="black" />
              </MDBBtn>
            </div>
          )}

          <table style={{ width: "100%" }}>
            <tr>
              <th style={{ width: "20%" }}>
                <div className="insideBoxes">
                  Anlagedatum{" "}
                  <DatePicker
                    selected={anlageDatum || new Date()}
                    onChange={(date) => setAnlageDatum(date)}
                    customInput={
                      <BsFilter size={25} style={{ marginLeft: 10 }} />
                    }
                  />
                </div>
              </th>
              <th style={{ width: "25%" }}>Tipp-Titel</th>
              <th>
                <div className="insideBoxes">
                  Anzeigeort{" "}
                  <MDBDropdown style={{ fontSize: "14px" }}>
                    <MDBDropdownToggle caret className="outerBtn">
                      <BsFilter size={25} />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu basic className="items">
                      <MDBDropdownItem onClick={() => setAnzeigeFilter("Alle")}>
                        Alle
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        onClick={() => setAnzeigeFilter("Header Homescreen")}
                      >
                        Header Homescreen
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        onClick={() => setAnzeigeFilter("Footer Homescreen")}
                      >
                        Footer Homescreen
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </div>
              </th>
              <th>
                <div className="insideBoxes">
                  Bewertung{" "}
                  <MDBDropdown style={{ fontSize: "14px" }}>
                    <MDBDropdownToggle caret className="outerBtn">
                      <BsFilter size={25} />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu basic className="items">
                      <MDBDropdownItem
                        onClick={() => setBewertungsFilter("Bewertung hoch")}
                      >
                        Bewertung hoch
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        onClick={() => setBewertungsFilter("Bewertung niedrig")}
                      >
                        Bewertung niedrig
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </div>
              </th>
              <th />
            </tr>
            {filteredTipps.length > 0 && selectedTip
              ? filteredTipps.map((item, index) => {
                  if (item.id === selectedTip.id) {
                    return (
                      <TippsRow
                        item={item}
                        index={index}
                        toggleLoading={toggleTipp}
                        active={true}
                      />
                    )
                  }
                  return (
                    <TippsRow
                      item={item}
                      index={index}
                      toggleLoading={toggleTipp}
                      active={false}
                    />
                  )
                })
              : ""}
          </table>
          <p
            className="normalp benutzer"
            style={{
              paddingRight: 10,
              paddingLeft: 10,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            {filteredTipps.length} Tipps
          </p>
        </div>
      </main>
    </div>
  )
}
