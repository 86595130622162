import {db, firebase} from "../components/Firebase";
import {MDBDropdownItem} from "mdbreact";
import React from "react";

export function makeId(length = 10) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const getNavBarOnUsersRole = (role) => {
    let _role = !!role ? role : "Benutzer";
    const items = {
        "Administrator": ["dashboard", "benutzer", "benachrichtigungen", "tipps", "einstellungen", "logincodes"],
        "Retailer": ["benutzer"],
        "Mitarbeiter": ["benutzer"],
        "Benutzer": ["benutzer"]
    };

    return items[_role];
}

export const isNavBarItemVisibleForRole = (navBarKey, role) => {
    const items = getNavBarOnUsersRole(role);
    return !!items ? !!items.find(i => i === navBarKey) : false;
}


export const getLoggedInUser = async () => {
    const currentUser = firebase.auth().currentUser;
    if(!!currentUser && currentUser.uid){
        const user = await db.collection("Benutzer")
            .doc(firebase.auth().currentUser.uid).get();
        let data = user.data();
        if(!!data && !!data.data){
            data = data.data;
        }
        if(!!!data){
            await firebase.auth().signOut();
            window.location.href = "/";
        }
        return {
            uid: currentUser.uid,
            ...data
        };
    }
    return null;
}

export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const changeUsersPassword = async (userId, email, newPw) => {
    return new Promise(resolve => {
        try {
            if(!!userId && !!email && !!newPw){
                const xhr = new XMLHttpRequest()

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            if (xhr.responseText === "Changed Password successfully!") {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(false);
                        }
                    }
                }
                xhr.open(
                    "get",
                    "https://europe-west3-forgtin-62d8f.cloudfunctions.net/manageUser?userId=" +
                    userId +
                    "&mode=updatePassword&newPw=" +
                    newPw +
                    "&email=" +
                    email,
                    true
                )
                xhr.send();
            } else {
                resolve(false);
            }
        } catch(e){
            console.error(e);
            resolve(false);
        }
    });
}

export const getCountryOptions = (setAction) => {
    let countries = [
        "Österreich",
        "Deutschland",
        "Schweiz",
        "Belgien",
        "Bulgarien",
        "Dänemark",
        "Estland",
        "Finnland",
        "Frankreich",
        "Griechenland",
        "Irland",
        "Italien",
        "Kroatien",
        "Lettland",
        "Litauen",
        "Luxemburg",
        "Malta",
        "Niederlande",
        "Polen",
        "Portugal",
        "Rumänien",
        "Schweden",
        "Slowakei",
        "Slowenien",
        "Spanien",
        "Tschechien",
        "Ungarn",
        "Zypern"
    ];
    return countries.map(c => {
        return <MDBDropdownItem
            onClick={() => setAction(c)}
        >
            {c}
        </MDBDropdownItem>
    })
}

export const searchStrings = (value, toSearch) => {
    return (!!value?value:"").toLowerCase().indexOf((!!toSearch ? toSearch : "").toLowerCase()) >= 0;
}

export const compareEmails = ( a, b ) => {
    if ( a.email < b.email ){
        return -1;
    }
    if ( a.email > b.email ){
        return 1;
    }
    return 0;
}