import * as firebase from "firebase"
import "firebase/firestore"

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDA_VDjzFNb-xaVoCdFXkB1qFATqNLFqww",
  authDomain: "forgtin-62d8f.firebaseapp.com",
  databaseURL: "https://forgtin-62d8f.firebaseio.com",
  projectId: "forgtin-62d8f",
  storageBucket: "forgtin-62d8f.appspot.com",
}

let x = firebase.initializeApp(firebaseConfig)
let y = firebase.initializeApp(firebaseConfig, "user-creation")


export const db = firebase.firestore()

export const getAdminData = firebase
  .firestore()
  .collection("Admin")
  .doc("admin")
  .get()
  .then((doc) => {
    if (doc.exists) {
      return doc.data()
    } else {
      return undefined
    }
  })

export { x as firebase, y as firebaseAuth }
