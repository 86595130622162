import React, { useEffect } from "react"
import { FaPen } from "react-icons/fa"
import { MDBBtn } from "mdbreact"
import { AiFillCopy } from "react-icons/ai"
import format from "date-fns/format"
import EditTipModal from "./EditTippModal"
import Rating from "@material-ui/lab/Rating"

export default function TippsRow(props) {
  return (
    <tr className={props.active ? "tippsRow active" : "tippsRow"}>
      <td>{format(props.item.anlage.toDate(), "d.MM.yyyy")}</td>
      <td>{props.item.title}</td>
      <td>{props.item.anzeige}</td>
      <td>
        <Rating
          name="read-only"
          value={props.item.bewertung}
          readOnly
          precision={0.1}
        />
      </td>
      <td
        style={{
          paddingRight: "15px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <EditTipModal
          name={props.item.message}
          id={props.item.id}
          anzeige={props.item.anzeige}
          anlage={props.item.anlage}
          bewertung={props.item.bewertung}
          index={props.index}
          title={props.item.title}
          bewertungen={props.item.bewertungen}
          titleEn={props.item.titleEn}
          messageEn={props.item.messageEn}
          toggleLoading={() => props.toggleLoading()}
        />
      </td>
    </tr>
  )
}
