import React, {Component, useState} from "react"
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact"
import de from "date-fns/locale/de"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
// import { SingleDatePicker } from "react-dates";
// import moment from "moment";
import FooterBox from "../FooterBox"

import {db, firebase, firebaseAuth} from "../Firebase"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {getCountryOptions} from "../../utils/globals";
registerLocale("de", de)

var numbers = /^[0-9]+$/
var letters = /^[a-zA-ZäüöÄÜÖ]+$/

export default function AddAdminUserModal({onAfterSubmit}) {

    const [modal, setModal] = useState(false);
    const [vorname, setVorname] = useState("");
    const [nachname, setNachname] = useState("");
    const [email, setEmail] = useState("");
    const [land, setLand] = useState("Österreich");
    const [role, setRole] = useState("Administrator");
    const [passwort, setPasswort] = useState("");
    const [kette, setKette] = useState("");
    const [filiale, setFiliale] = useState("");
    const [isSending, setIsSending] = useState(false);
    const [ort, setOrt] = useState("");
    const [plz, setPlz] = useState("");
    const [adresse, setAdresse] = useState("");

    const clearAllData = () => {
      setVorname("");
      setNachname("");
      setEmail("");
      setLand("Österreich");
      setRole("Administrator");
      setKette("");
      setFiliale("");
      setPasswort("");
    }

  const handleSave = async () => {
    setIsSending(true);
    let errors = [];
    if(!!!vorname){
      errors.push('vorname');
    }
    if(!!!nachname){
      errors.push('nachname');
    }
    if(!!!email){
      errors.push('email');
    }
    if(!!!land){
      errors.push('land');
    }
    if(!!!role){
      errors.push('role');
    }
    if(!!!passwort){
      errors.push('passwort');
    }

    if(role == "Retailer" && !!!kette){
      errors.push('kette');
    }
    if(role == "Retailer" && !!!filiale){
      errors.push('filiale');
    }

    if(errors.length > 0){
      alert("Füllen Sie bitte alle Felder aus")
      setIsSending(false);
      return false;
    }

    try {
      const res = await firebaseAuth
          .auth()
          .createUserWithEmailAndPassword(email, passwort);

      if(!!res && !!res.user && !!res.user.uid){
        await db.collection("Benutzer")
            .doc(res.user.uid)
            .set({
              data: {
                email: email,
                vorname: vorname,
                nachname: nachname,
                land: land,
                rolle: role,
                kette: kette,
                filiale: filiale,
                adresse: adresse,
                plz: plz,
                ort: ort
              },
            });

      }
      clearAllData();
      setIsSending(false);
      toggleModal();
      if(!!onAfterSubmit){
        onAfterSubmit();
      }
    } catch(e){
      setIsSending(false);
      console.error(e);
      if (e.code === "auth/email-already-in-use") {
        alert("Diese Email Adresse ist bereits in Verwendung")
      } else if (e.code === "auth/invalid-email") {
        alert("Bitte überprüfen Sie Ihre Email Adresse (Format)")
      } else {
        alert("Passwort muss mindestens 6 stellig sein")
      }
    }
  }

  const toggleModal = () => {
    setModal(!!!modal);
  }

  const generate = () => {
    var length = 16,
        charset =
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        code = ""
    for (var i = 0, n = charset.length; i < length; ++i) {
      code += charset.charAt(Math.floor(Math.random() * n))
    }
    setPasswort(code);
  }

  return (
      <MDBContainer style={{ paddingLeft: "0px", marginLeft: "0px", marginRight: "0px", paddingRight: "0px" }}>
        <div style={{width: "100%", textAlign: "right"}}>
          <MDBBtn
              color="dark"
              onClick={toggleModal}
              className="benutzerAnlegenBtn"
          >
            Admin Benutzer anlegen
          </MDBBtn>
        </div>

        <MDBModal isOpen={!!modal} toggle={toggleModal} centered>
          <MDBModalHeader toggle={toggleModal}>
            Admin Benutzer anlegen
          </MDBModalHeader>
          <MDBModalBody>
            <Row>
              <Col>
                <label htmlFor="vorname">Vorname</label>
                <input
                    type="text"
                    id="vorname"
                    className="form-control form-control-md"
                    onChange={({target}) => setVorname(target.value)}
                    value={vorname}
                    name="vorname"
                />
              </Col>
              <Col>
                <label htmlFor="nachname">Nachname</label>
                <input
                    type="text"
                    id="nachname"
                    className="form-control form-control-md"
                    onChange={({target}) => setNachname(target.value)}
                    value={nachname}
                    name="nachname"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="email">E-Mail</label>
                <input
                    type="text"
                    id="email"
                    className="form-control form-control-md"
                    onChange={({target}) => setEmail(target.value)}
                    value={email}
                    name="email"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <label htmlFor="land">Land</label>
                <MDBDropdown id={"newUserLand"} style={{ fontSize: "14px" }}>
                  <MDBDropdownToggle caret className="drops">
                    {land}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu basic>
                    {getCountryOptions(setLand)}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </Col>
              <Col sm={6}>
                <label htmlFor="rolle">Rolle</label>
                <MDBDropdown id={"newUserRolle"} style={{ fontSize: "14px" }}>
                  <MDBDropdownToggle caret className="drops">
                    {role}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu basic>
                    <MDBDropdownItem
                        onClick={() => setRole("Administrator")}
                    >
                      Administrator
                    </MDBDropdownItem>
                    <MDBDropdownItem
                        onClick={() => setRole("Retailer")}
                    >
                      Retailer
                    </MDBDropdownItem>
                    <MDBDropdownItem
                        onClick={() => setRole("Mitarbeiter")}
                    >
                      Mitarbeiter
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </Col>
            </Row>
            {role == "Retailer" && [
                <Row>
                  <Col xs={12}>
                    <label htmlFor="adresse">Adresse</label>
                    <input
                        type="text"
                        id="adresse"
                        className="form-control form-control-md"
                        value={adresse}
                        onChange={(event) => setAdresse(event.target.value)}
                    />
                  </Col>
                </Row>,
              <Row>
                <Col xs={6}>
                  {!plz.match(numbers) && plz != "" && (
                      <p style={{ color: "red", fontSize: 14 }}>
                        Bitte nur Zahlen eingeben!
                      </p>
                  )}
                  <label htmlFor="plz">PLZ</label>
                  <input
                      type="text"
                      id="plz"
                      className="form-control form-control-md"
                      value={plz}
                      onChange={(event) => setPlz(event.target.value)}
                  />
                </Col>
                <Col xs={6}>
                  {!ort.match(letters) && ort != "" && (
                      <p style={{ color: "red", fontSize: 14 }}>
                        Bitte nur Buchstaben eingeben!
                      </p>
                  )}
                  <label htmlFor="ort">Ort</label>
                  <input
                      type="text"
                      id="ort"
                      className="form-control form-control-md"
                      value={ort}
                      onChange={(event) => setOrt(event.target.value)}
                  />
                </Col>
              </Row>,
              <Row>
                <Col xs={12}>
                  <label htmlFor="kette">Kette / Gruppenname</label>
                  <input
                      type="text"
                      id="kette"
                      className="form-control form-control-md"
                      onChange={({target}) => setKette(target.value)}
                      value={kette}
                      name="kette"
                  />
                </Col>
              </Row>,
                <Row>
                  <Col xs={12}>
                    <label htmlFor="filiale">Ortsbezeichnung / Filiale</label>
                    <input
                        type="text"
                        id="filiale"
                        className="form-control form-control-md"
                        onChange={({target}) => setFiliale(target.value)}
                        value={filiale}
                        name="filiale"
                    />
                  </Col>
                </Row>
                ]

            }

            <label htmlFor="newPw">Passwort</label>
            <Row>
              <Col style={{ display: "flex" }}>
                <MDBBtn
                    color="white"
                    className="classicBtn"
                    style={{ width: "40%", minWidth: "92px" }}
                    id="newPw"
                    onClick={generate}
                >
                  Passwort generieren
                </MDBBtn>
                <input
                    type="text"
                    id="passwort"
                    name="passwort"
                    className="form-control form-control-md pwInput"
                    value={passwort}
                    onChange={({target}) => setPasswort(target.value)}
                />
              </Col>
            </Row>

          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={toggleModal}>
              Schließen
            </MDBBtn>
            <MDBBtn color="dark" onClick={handleSave} disabled={!!isSending}>
              Benutzer anlegen
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
  )

};
