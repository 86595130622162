import React from "react";
import { MDBBtn } from "mdbreact";

export default function BenutzerAnzeigeBtn(props) {
  const handleClick = () => {
    props.click(props.wholeData);
  };

  return (
    <button
      className="btn-default btn Ripple-parent btnBenutzer"
      onClick={handleClick}
      id={props.id}
    >
      {props.data.nachname + " "}
      {props.data.vorname}
    </button>
  );
}
