import React, {useEffect, useState} from "react";
import {MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle} from "mdbreact";
import { db } from "../Firebase";
import "react-confirm-alert/src/react-confirm-alert.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import AdminUsersRow from "./AdminUsersRow";
import AddAdminUserModal from "./AddAdminUserModal";
import {compareEmails, searchStrings} from "../../utils/globals"; // Import css

export default function AdminUsers({data}) {

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if(!!users && users.length){
      let filtered = users.filter(u => {
        if(!!!search || search.length == 0){
          return true;
        } else {
          return searchStrings(u.email, search)
            || searchStrings(u.vorname, search)
            || searchStrings(u.nachname, search)
            || searchStrings(u.kette, search)
            || searchStrings(u.filiale, search);
        }
      });
      filtered.sort(compareEmails);
      setFilteredUsers(filtered);
    }

  }, [search]);

  const getData = async () => {
    const _users = []
    setLoading(true);
    const toSet = await db
        .collection("Benutzer")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc, index) {
            const data = doc.data()["data"];
            if(!!data && (data.rolle == "Administrator" || data.rolle == "Retailer" || data.rolle == "Mitarbeiter")){
              _users.push({
                ...data,
                id: doc.id,
                index: index,
              })
            }
          })
          return _users
    });
    setLoading(false);
    setUsers(toSet);
    toSet.sort(compareEmails);

    setFilteredUsers(toSet);
  }

  useEffect(() => {
    getData()
  }, [])

  return <div>
    {loading && (
        <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255,255,255,0.7)",
              zIndex: 9999,
              position: "absolute",
              top: 0,
              left: 0,
            }}
        >
          <CircularProgress />
        </div>
    )}
    <div>
      <div style={{width: "100%", textAlign: "left", paddingTop: "5px", paddingBottom: "5px"}}>
        <AddAdminUserModal
            onAfterSubmit={getData}
        />
      </div>
      <div className="topContainer">
        <p>Admin Benutzer</p>
      </div>
      <div className="filterBox" style={{marginTop: 5, marginBottom: 5}}>
        <input type="hidden" value="something"/>

        <input
            type="text"
            name="name-filter"
            placeholder="Benutzer suchen"
            className="form-control form-control-md"
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            value={search}
            style={{ marginBottom: 5 }}
            autoComplete="off"
        />
      </div>
      <div className="mainContainer">
        <table style={{ width: "100%" }}>
          <tr>
            <th style={{ width: "20%" }}>
              <div className="insideBoxes">
                E-Mail
              </div>
            </th>
            <th>
              <div className="insideBoxes">
                Vorname
              </div>
            </th>
            <th>
              <div className="insideBoxes">
                Nachname
              </div>
            </th>
            <th>
              <div className="insideBoxes">
                Rolle
              </div>
            </th>
            <th>
              <div className="insideBoxes">
                Kette
              </div>
            </th>
            <th>
              <div className="insideBoxes">
                Filiale
              </div>
            </th>
            <th>
              <div className="insideBoxes">
                Adresse
              </div>
            </th>
            <th />
          </tr>
          {filteredUsers.length > 0
              ? filteredUsers.map((item, index) => {
                return (
                    <AdminUsersRow
                        item={item}
                        index={index}
                        active={false}
                        onAfterSubmit={() => getData()}
                    />
                )
              })
              : ""}
        </table>
      </div>
    </div>
  </div>

}
