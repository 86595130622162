import React, { Component } from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { db, firebase } from "../Firebase";

class AddFrageModal extends Component {
  state = {
    modal14: false,
    mode: "Texteingabe",
    titel: "",
  };

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  handleTitel = (e) => {
    this.setState({ titel: e.target.value });
  };

  handleSave = () => {
    const current = this;
    if (this.state.titel) {
      db.collection("Admin")
        .doc("admin")
        .update({
          Fragen: firebase.firestore.FieldValue.arrayUnion({
            frage: this.state.titel,
            modalität: this.state.mode,
          }),
        })
        .then(() => {
          this.setState(
            {
              titel: "",
              mode: "Texteingabe",
              modal14: false,
            },
            () => {
              alert("Frage wurde hinzugefügt!");
              window.location.href = "/einstellungen";
            }
          );
        });
    } else {
      alert("Bitte Titel ausfüllen!");
    }
  };

  render() {
    return (
      <MDBContainer style={{ paddingLeft: "0px" }}>
        <MDBBtn color="dark" onClick={this.toggle(14)}>
          Frage hinzufügen
        </MDBBtn>
        <MDBModal isOpen={this.state.modal14} toggle={this.toggle(14)} centered>
          <MDBModalHeader toggle={this.toggle(14)}>
            Frage hinzufügen
          </MDBModalHeader>
          <MDBModalBody>
            <label htmlFor="newFrage">Titel</label>
            <input
              type="text"
              id="newFrage"
              className="form-control form-control-md"
              onChange={this.handleTitel}
              value={this.state.titel}
              style={{ marginBottom: "15px" }}
            />
            <label htmlFor="newBtn">Modalität</label>
            <MDBDropdown id="newBtn" style={{ fontSize: "13px" }}>
              <MDBDropdownToggle caret className="drops">
                {this.state.mode}
              </MDBDropdownToggle>
              <MDBDropdownMenu basic>
                <MDBDropdownItem
                  onClick={() => this.setState({ mode: "Texteingabe" })}
                >
                  Texteingabe
                </MDBDropdownItem>
                <MDBDropdownItem
                  onClick={() => this.setState({ mode: "Schieberegler" })}
                >
                  Schieberegler
                </MDBDropdownItem>
                <MDBDropdownItem
                  onClick={() =>
                    this.setState({ mode: "Visuelle Analogskala" })
                  }
                >
                  Visuelle Analogskala
                </MDBDropdownItem>
                <MDBDropdownItem
                  onClick={() => this.setState({ mode: "Checkbox" })}
                >
                  Checkbox
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggle(14)}>
              Schließen
            </MDBBtn>
            <MDBBtn color="dark" onClick={this.handleSave}>
              Änderungen speichern
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default AddFrageModal;
