import React, { useEffect } from "react"
import "./index.css"
import ForgTinLogo from "../ForgTinLogo"
import { MDBBtn } from "mdbreact"
import { firebase } from "../Firebase"

export default function Auth() {
  const [name, setName] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [error, setError] = React.useState(false)

  const onLogin = async () => {

    try {
      await firebase
          .auth()
          .signInWithEmailAndPassword(name, password);
    } catch(e){
      setError(true);
    }
  }

  const handleKey = (e) => {
    if (e.key === "Enter") {
      onLogin()
    }
  }

  function handleName(e) {
    setName(e.target.value)
  }

  function handlePassword(e) {
    setPassword(e.target.value)
  }

  return (
    <div className="authContainer">
      <div className="outer">
        <ForgTinLogo width="70%" />

        <div>
          {error === true ? (
            <p style={{ color: "rgba(240,0,0,1)" }}>
              Name oder Passwort sind falsch. Bitte versuchen Sie es erneut.
            </p>
          ) : (
            ""
          )}

          <label htmlFor="loginname">E-Mail</label>
          <input
            type="text"
            id="loginname"
            name="loginname"
            className="form-control form-control-md"
            onChange={handleName}
            onKeyPress={handleKey}
          />
        </div>
        <div>
          <label htmlFor="loginpassword">Passwort</label>
          <input
            type="password"
            id="loginpassword"
            name="loginpassword"
            className="form-control form-control-md"
            onChange={handlePassword}
            onKeyPress={handleKey}
          />
        </div>
        <MDBBtn color="dark" onClick={onLogin}>
          Login
        </MDBBtn>
      </div>
    </div>
  )
}
