import React, { Component } from "react"
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact"
import de from "date-fns/locale/de"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
// import { SingleDatePicker } from "react-dates";
// import moment from "moment";
import FooterBox from "../FooterBox"

import {db, firebase, firebaseAuth} from "../Firebase"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {getCountryOptions, getLoggedInUser} from "../../utils/globals";
registerLocale("de", de)

var numbers = /^[0-9]+$/
var letters = /^[a-zA-ZäüöÄÜÖ]+$/

class AddUserModal extends Component {
  state = {
    modal14: false,
    study: false,
    vorname: "",
    nachname: "",
    ort: "",
    plz: "",
    adresse: "",
    email: "",
    land: "Österreich",
    passwort: "",
    gb: new Date(),
    focused: false,
    showFlash: false,
    flashMessage: "",
    flashType: "",
    isSending: false,
  }
  componentDidMount() {
    this.listenUser()
  }

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    })
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleCheck = () => {
    this.setState({ study: !this.state.study })
  }

  listenUser = () => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
      } else {
      }
    })
  }

  handleSave = async () => {
    this.setState({isSending: true});
    const {
      vorname,
      nachname,
      email,
      plz,
      ort,
      land,
      adresse,
      passwort,
      gb,
      study,
    } = this.state
    if (
      vorname &&
      nachname &&
      email &&
      plz &&
      ort &&
      land &&
      adresse &&
      passwort &&
      gb &&
      ort.match(letters) &&
      plz.match(numbers)
    ) {
      const vor = new Date()
      vor.setHours(11)

      const nach = new Date()
      nach.setHours(16)

      let retailer = "";
      const user = await getLoggedInUser();
      if(!!user && !!user.rolle && user.rolle == "Retailer"){
        retailer = user.uid;
      }

      firebaseAuth
        .auth()
        .createUserWithEmailAndPassword(email, passwort)
        .then((res) => {

            if(!!res && res.user && res.user.uid){
              db.collection("Benutzer")
                  .doc(res.user.uid)
                  .set({
                    data: {
                      email: email,
                      geburtsdatum: gb,
                      vorname: vorname,
                      nachname: nachname,
                      erinnerungen: true,
                      erinnerungenVor: vor,
                      erinnerungenNach: nach,
                      land: land,
                      ort: ort,
                      plz: plz,
                      adresse: adresse,
                      studienteilnehmer: study,
                      eingabeWiederErlaubt: true,
                      gesperrt: false,
                      retailer: retailer
                    },
                    eintraege: [],
                    lastEingabe: "keine",
                    ersterEintrag: {},
                    token: "",
                  })
                  .then(() => {
                    this.setState(
                        {
                          vorname: "",
                          nachname: "",
                          ort: "",
                          plz: "",
                          adresse: "",
                          email: "",
                          land: "Österreich",
                          study: false,
                          passwort: "",
                          modal14: false,
                          flashMessage: "Benutzer erfolgreich hinzugefügt!",
                          flashType: "success",
                          showFlash: true,
                          gb: new Date(),
                          isSending: false,
                        },
                        () => {
                          this.props.getAll()
                          this.props.clear()
                        }
                    )
                  })
            }
            })
        .catch((error) => {
          this.setState({isSending: false});
          if (error.code === "auth/email-already-in-use") {
            alert("Diese Email Adresse ist bereits in Verwendung")
          } else if (error.code === "auth/invalid-email") {
            alert("Bitte überprüfen Sie Ihre Email Adresse (Format)")
          } else {
            alert("Passwort muss mindestens 6 stellig sein")
          }
        })
    } else {
      this.setState({
        flashMessage: "Bitte alle Felder ausfüllen",
        flashType: "error",
        showFlash: true,
        isSending: false
      })
    }
  }

  generate = () => {
    var length = 16,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      code = ""
    for (var i = 0, n = charset.length; i < length; ++i) {
      code += charset.charAt(Math.floor(Math.random() * n))
    }
    this.setState({ passwort: code })
  }

  setLand = (value) => {
    this.setState({land: value})
  }

  render() {
    return (
      <MDBContainer style={{ paddingLeft: "0px", marginLeft: "0px" }}>
        <MDBBtn
          color="dark"
          onClick={this.toggle(14)}
          className="benutzerAnlegenBtn"
        >
          Benutzer anlegen
        </MDBBtn>
        <MDBModal isOpen={this.state.modal14} toggle={this.toggle(14)} centered>
          <MDBModalHeader toggle={this.toggle(14)}>
            Benutzer anlegen
          </MDBModalHeader>
          <MDBModalBody>
            <Row>
              <Col>
                <label htmlFor="vorname">Vorname</label>
                <input
                  type="text"
                  id="vorname"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.vorname}
                  style={{ marginBottom: "15px" }}
                  name="vorname"
                />
              </Col>
              <Col>
                <label htmlFor="nachname">Nachname</label>
                <input
                  type="text"
                  id="nachname"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.nachname}
                  style={{ marginBottom: "15px" }}
                  name="nachname"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="email">E-Mail</label>
                <input
                  type="text"
                  id="email"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.email}
                  style={{ marginBottom: "15px" }}
                  name="email"
                />
              </Col>
              <Col>
                <label htmlFor="adresse">Adresse</label>
                <input
                  type="text"
                  id="adresse"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.adresse}
                  style={{ marginBottom: "15px" }}
                  name="adresse"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="plz">PLZ</label>
                {!this.state.plz.match(numbers) && this.state.plz !== "" && (
                  <p style={{ color: "red", fontSize: 14 }}>
                    Bitte nur Zahlen eingeben!
                  </p>
                )}
                <input
                  type="text"
                  id="plz"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.plz}
                  style={{ marginBottom: "15px" }}
                  name="plz"
                />
              </Col>
              <Col>
                <label htmlFor="ort">Ort</label>
                {!this.state.ort.match(letters) && this.state.ort !== "" && (
                  <p style={{ color: "red", fontSize: 14 }}>
                    Bitte nur Buchstaben eingeben!
                  </p>
                )}
                <input
                  type="text"
                  id="ort"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.ort}
                  style={{ marginBottom: "15px" }}
                  name="ort"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="land">Land</label>
                {/* <input
                  type="text"
                  id="land"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.land}
                  style={{ marginBottom: "15px" }}
                  name="land"
                /> */}
                <MDBDropdown id={"newUserLand"} style={{ fontSize: "14px" }}>
                  <MDBDropdownToggle caret className="drops">
                    {this.state.land}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu basic>
                    {getCountryOptions(this.setLand)}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </Col>
              <Col>
                <label htmlFor="gb">Geburtsdatum</label>
                <DatePicker
                  id="gb"
                  selected={this.state.gb}
                  onChange={(date) => this.setState({ gb: date })}
                  locale="de"
                  dateFormat="d.M.yyyy"
                  style={{ width: "40%" }}
                />
                {/* <SingleDatePicker
                  date={moment(this.state.gb)} // momentPropTypes.momentObj or null
                  onDateChange={(date) => this.setState({ gb: date })} // PropTypes.func.isRequired
                  focused={this.state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                  id="your_unique_id" // PropTypes.string.isRequired,
                /> */}
              </Col>
            </Row>
            <label htmlFor="newPw">Passwort</label>
            <Row>
              <Col style={{ display: "flex" }}>
                <MDBBtn
                  color="white"
                  className="classicBtn"
                  style={{ width: "40%", minWidth: "92px" }}
                  id="newPw"
                  onClick={this.generate}
                >
                  Passwort generieren
                </MDBBtn>
                <input
                  type="text"
                  id="passwort"
                  name="passwort"
                  className="form-control form-control-md pwInput"
                  value={this.state.passwort}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ display: "flex", alignItems: "center" }}>
                <label htmlFor="study" style={{ margin: 0 }}>
                  Studienteilnehmer
                </label>
                <input
                  type="checkbox"
                  id="study"
                  className="checky"
                  name="study"
                  onChange={this.handleCheck}
                />
              </Col>
            </Row>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggle(14)}>
              Schließen
            </MDBBtn>
            <MDBBtn color="dark" onClick={this.handleSave} disabled={!!this.state.isSending}>
              Benutzer anlegen
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        {this.state.showFlash && (
          <FooterBox
            type={this.state.flashType}
            message={this.state.flashMessage}
            onExit={() => this.setState({ showFlash: false })}
          />
        )}
      </MDBContainer>
    )
  }
}

export default AddUserModal
