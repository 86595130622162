import React, { Component } from "react"
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact"
import { db, firebase } from "../Firebase"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import FooterBox from "../FooterBox"
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact"

class AddTipModal extends Component {
  state = {
    modal15: false,
    tipName: "",
    anzeige: "Header Homescreen",
    tipTitle: "",
    flashType: "",
    flashMessage: "",
    showFlash: false,
    tipMessageEn: "",
    tipTitleEn: "",
  }

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    })
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleCheck = () => {
    this.setState({ study: !this.state.study })
  }

  handleSave = () => {
    this.props.toggleLoading()
    const { anzeige, tipName, tipTitle, tipMessageEn, tipTitleEn } = this.state
    if (anzeige && tipName) {
      db.collection("Admin")
        .doc("admin")
        .update({
          Tipps: firebase.firestore.FieldValue.arrayUnion({
            message: tipName,
            anzeige: anzeige,
            bewertung: 0,
            anlage: new Date(),
            id: Math.random() * 1000,
            title: tipTitle,
            bewertungen: [],
            titleEn: tipTitleEn,
            messagEn: tipMessageEn,
          }),
        })
        .then(() => {
          this.setState(
            {
              anzeige: "Header Homescreen",
              tipName: "",
              tipTitle: "",
              tipTitleEn: "",
              tipMessageEn: "",
              modal15: false,
            },
            () => {
              this.props.toggleLoading()
              this.setState({
                flashMessage: "Tipp wurde hinzugefügt!",
                flashType: "success",
                showFlash: true,
              })
            }
          )
        })
    } else {
      this.props.toggleLoading()
      this.setState({
        flashMessage: "Bitte alle Felder ausfüllen",
        flashType: "error",
        showFlash: true,
      })
    }
  }

  render() {
    return (
      <div className="tippsBtn">
        <MDBBtn
          color="dark"
          onClick={this.toggle(15)}
          style={{ width: "100%", paddingRight: 0 }}
        >
          Tipp erstellen
        </MDBBtn>
        <MDBModal isOpen={this.state.modal15} toggle={this.toggle(15)} centered>
          <MDBModalHeader toggle={this.toggle(15)}>
            Tipp erstellen
          </MDBModalHeader>
          <MDBModalBody>
            <Row>
              <Col>
                <label htmlFor="tipTitle">Tipp-Titel (Deutsch)</label>
                <input
                  type="text"
                  id="tipTitle"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.tipTitle}
                  style={{ marginBottom: "15px" }}
                  name="tipTitle"
                />
                <label htmlFor="tipTitle">Tipp-Titel (Englisch)</label>
                <input
                  type="text"
                  id="tipTitleEn"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.tipTitleEn}
                  style={{ marginBottom: "15px" }}
                  name="tipTitleEn"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="tipName">Tipp-Text (Deutsch)</label>
                <textarea
                  type="text"
                  id="tipName"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.tipName}
                  style={{ marginBottom: "15px" }}
                  name="tipName"
                />
                <label htmlFor="tipName">Tipp-Text (Englisch)</label>
                <textarea
                  type="text"
                  id="tipName"
                  className="form-control form-control-md"
                  onChange={this.handleChange}
                  value={this.state.tipMessageEn}
                  style={{ marginBottom: "15px" }}
                  name="tipMessageEn"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="anzeige">Anzeigeort</label>
                <MDBDropdown id="anzeige" style={{ fontSize: "14px" }}>
                  <MDBDropdownToggle caret className="drops">
                    {this.state.anzeige}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu basic>
                    <MDBDropdownItem
                      onClick={() =>
                        this.setState({ anzeige: "Header Homescreen" })
                      }
                    >
                      Header Homescreen
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      onClick={() =>
                        this.setState({ anzeige: "Footer Homescreen" })
                      }
                    >
                      Footer Homescreen
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </Col>
            </Row>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={this.toggle(15)}>
              Schließen
            </MDBBtn>
            <MDBBtn color="dark" onClick={this.handleSave}>
              Tipp erstellen
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        {this.state.showFlash && (
          <FooterBox
            type={this.state.lashType}
            message={this.state.flashMessage}
            onExit={() => this.setState({ showFlash: false })}
          />
        )}
      </div>
    )
  }
}

export default AddTipModal
