import React from "react";
import "./index.css";
import { MDBBtn } from "mdbreact";
import { firebase } from "../Firebase";

export default function AbmeldeScreen() {
  const abmelden = () => {
    firebase.auth().signOut();
    window.location.href = "/";
  };

  return (
    <div>
      <main>
        <div className="abmeldeContainer">
          <div className="abmelden">
            <h4>Möchten Sie sich wirklich abmelden?</h4>
            <div>
              <MDBBtn color="dark" onClick={abmelden}>
                Abmelden
              </MDBBtn>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
